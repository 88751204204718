import React, { useState } from "react";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { Link } from "react-router-dom";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import { PoolParticipant } from "@/models";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import useMe from "@/entities/me/lib/use";

type Data = PoolParticipant & {
  filters?: any[];
};

type Params = Partial<Data>;

type TableProps = ProTableProps<Data, Params>;

const EducationEndingTomorrowTable: React.FC<TableProps> = ({ ...props }) => {
  const [error, setError] = useState<Error | null>(null);
  const member = useMe();
  const request: ProTableProps<PoolParticipant, any>["request"] = async (
    params,
    sort,
    filter,
  ) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: "/api/pool-participants/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    if (params.filters) {
      config.data.filters.push(...params.filters);
    }

    const now = new Date().toISOString();
    let nowDateAfter24h = new Date();
    nowDateAfter24h.setDate(nowDateAfter24h.getDate() + 1);
    const nowAfter24h = nowDateAfter24h.toISOString();

    config.data.filters.push(
      { field: "pool.status", operator: "!=", value: "completed" },
      { field: "status", operator: "!=", value: "completed" },
      { field: "ends_at", operator: ">", value: now },
      { field: "ends_at", operator: "<", value: nowAfter24h },
      {
        type: "and",
        nested: [
          {
            field: "pool.course.status",
            operator: "=",
            value: "active",
            type: "or",
          },
          {
            field: "pool.course.status",
            operator: "=",
            value: "archival",
            type: "or",
          },
        ],
      },
    );

    config.data.includes.push({ relation: "member" }, { relation: "pool" });

    return axios
      .request<OrionRestIndexResponse<PoolParticipant>>(config)
      .then((res) => {
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    search: false,
    toolBarRender: false,
    request,
    pagination: {
      defaultPageSize: 20,
    },
    style: { width: "100%" },
    size: "small",
    columns: [
      {
        title: "ФИО",
        dataIndex: ["member", "full_name"],
        render: (text, record) => {
          if (member.permissions.includes("member:view")) {
            return (
              <Link to={`/manage/members/${record.member!.id}`}>{text}</Link>
            );
          }
          return text;
        },
      },
      {
        title: "Поток",
        dataIndex: ["pool", "name"],
        ellipsis: true,
        renderText: (text, { pool }) => (
          <Link to={`/manage/courses/${pool!.course_id}/pools/${pool!.id}`}>
            {text}
          </Link>
        ),
      },
      {
        title: "Планируемая дата завершения",
        dataIndex: "ends_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "ascend",
      },
    ],
  };

  if (error) throw error;

  return (
    <ProTable<Data>
      {...deepmergeProTableProps(defaultProps as any, props as any)}
    />
  );
};

export default EducationEndingTomorrowTable;
