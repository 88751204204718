import React from "react";
import { Button, Result, theme } from "antd";
import AuthButton from "@/shared/components/auth-button";
import { useNavigate } from "react-router-dom";
import useSpace from "@/entities/space/lib/use";
import Support from "@/pages/space/support";

const LoginRequiredPage: React.FC = () => {
  const navigate = useNavigate();
  const space = useSpace();
  const { token } = theme.useToken();

  return (
    <>
      <Result
        title="Требуется авторизация"
        extra={[
          <AuthButton key={"logout"} />,
          <Button key="home" onClick={() => navigate("/")}>
            Домой
          </Button>,
        ]}
        style={{ paddingBottom: token.padding }}
      />
      {space && (space.support_email || space.support_phone_number) && (
        <Support align="center" />
      )}
    </>
  );
};

export default LoginRequiredPage;
