import {
  ProForm,
  ProFormCheckbox,
  ProFormCheckboxGroupProps,
  ProFormItem,
  ProFormProps,
} from "@ant-design/pro-components";
import { Checkbox, message, Space, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import type { CheckboxProps } from "antd";
import { RolesFormProps } from "./form";
import { CheckboxOptionType } from "antd/lib";

type CheckboxFormFieldPermissionProps = {
  name: string;
  label: string;
  form: RolesFormProps["form"];
  options: ProFormCheckboxGroupProps["options"];
  onChange?: () => void;
};

const CheckboxFormFieldPermission: React.FC<
  CheckboxFormFieldPermissionProps
> = ({ name, label, form, options: _options, onChange: _onChange }) => {
  const names = ProForm.useWatch(name, form) as string[];

  const indeterminate =
    names && names.length > 0 && names!.length < _options!.length;
  const checkAll = _options!.length === names?.length;

  const onChange = () => {
    _onChange?.();
  };

  useEffect(() => {
    let newCheckedList = names;

    if (
      names?.length &&
      !names.includes(`${name}:view`) &&
      options?.some(
        (item) => (item as CheckboxOptionType).value === `${name}:view`,
      )
    ) {
      newCheckedList.push(`${name}:view`);
    }

    if (name === "course" && names) {
      if (names.includes("course:create") && !names.includes("course:update")) {
        newCheckedList.push("course:update");
      }
      if (names.includes("import:questions")) {
        if (!names.includes("course:update")) {
          newCheckedList = newCheckedList.filter(
            (item) => item !== "import:questions",
          );
          message.warning(
            "Для импорта вопросов необходимо Редактирование курсов",
          );
        }
      }
    }

    if (name === "member" && names) {
      if (names.includes("member:create") && !names.includes("member:update")) {
        newCheckedList.push("member:update");
      }
    }

    if (name === "org_structure" && names) {
      if (
        names.includes("org_structure:create") &&
        !names.includes("org_structure:update")
      ) {
        newCheckedList.push("org_structure:update");
      }
    }

    form!.setFieldsValue({
      [name]: newCheckedList?.length ? newCheckedList : undefined,
    });
  }, [names]);

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    const list: CheckboxProps["value"][] =
      _options?.map((item) => {
        if (typeof item === "object") {
          return item.value;
        }
      }) ?? [];
    form?.setFieldValue(name, e.target.checked ? list : []);
    _onChange?.();
  };

  const options = useMemo(
    () =>
      _options?.map((item) => {
        if (
          typeof item === "object" &&
          item.value === `${name}:view` &&
          names?.length &&
          names?.some((item: string) => item !== `${name}:view`)
        ) {
          return {
            ...item,
            disabled: true,
          };
        } else if (
          name === "course" &&
          typeof item === "object" &&
          item.value === `course:update` &&
          names?.length &&
          names?.some((item: string) => item === `course:create`)
        ) {
          return {
            ...item,
            disabled: true,
          };
        } else if (
          name === "member" &&
          typeof item === "object" &&
          item.value === `member:update` &&
          names?.length &&
          names?.some((item: string) => item === `member:create`)
        ) {
          return {
            ...item,
            disabled: true,
          };
        } else if (
          name === "org_structure" &&
          typeof item === "object" &&
          item.value === `org_structure:update` &&
          names?.length &&
          names?.some((item: string) => item === `org_structure:create`)
        ) {
          return {
            ...item,
            disabled: true,
          };
        } else {
          return item;
        }
      }),
    [names],
  );
  return (
    <Space direction={"vertical"}>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        <Typography.Text strong>{label}</Typography.Text>
      </Checkbox>
      <div style={{ paddingLeft: 20 }}>
        <ProFormCheckbox.Group
          name={name}
          layout="vertical"
          fieldProps={{ onChange }}
          options={options}
        />
      </div>
    </Space>
  );
};

export default CheckboxFormFieldPermission;
