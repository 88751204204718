const delovoy = {
  translation: {
    Подразделение: "Компания",
    подразделение: "компания",
    подразделениям: "компаниям",
    подразделением: "компанией",
    подразделения: "компании",
    "Старое подразделение": "Старая компания",
    "Новое подразделение": "Новая компания",

    Должность: "Учебная группа",
    должность: "учебная группа",
    должностью: "учебной группой",
    должности: "учебной группы",
    "Старая должность": "Старая учебная группа",
    "Новая должность": "Новая учебная группа",

    "Создать для указанных подразделений/должностей":
      "Создать для указанных компаний/учебных групп",

    "Минимальное количество подразделений/должностей – 1":
      "Минимальное количество компаний/учебных групп – 1",

    "Добавить подразделение/должность": "Добавить компанию/учебную группу",

    "Для указанных  подразделений/должностей не найдено обучение в матрице обучения.":
      "Для указанных компаний/учебных групп не найдено обучение в матрице обучения.",

    "Ошибка получения должностей": "Ошибка получения учебных групп",

    "Ошибка добавления должности": "Ошибка добавления учебной группы",

    "Добавить должность": "Добавить учебную группу",
    "Поиск по должности": "Поиск по учебной группе",

    "Должность дублируется, выберите другую Должность или удалите поле":
      "Учебная группа дублируется, выберите другую Учебную группу или удалите поле",
    "Подразделение успешно обновлено": "Компания успешно обновлена",
    "Не удалось обновить подразделение": "Не удалось обновить компанию",

    "Подразделение успешно удалено": "Компания успешно удалена",
    "Не удалось удалить Подразделение": "Не удалось удалить компанию",

    "Подразделение успешно cоздано": "Компания успешно создана",
    "Не удалось создать подразделение": "Не удалось создать компанию",

    "Должность успешно обновлена": "Учебная группа успешно обновлена",
    "Не удалось обновить должность": "Не удалось обновить учебную группу",

    "Должность успешно cоздана": "Учебная группа успешно создана",
    "Не удалось создать должность": "Не удалось создать учебную группу",

    "Должность успешно удалена": "Учебная группа успешно удалена",
    "Не удалось удалить Должность": "Не удалось удалить учебную группу",

    "Сочетание Подразделение/Должность дублируется, выберите другое Подразделение или удалите поле":
      "Сочетание Компания/Учебная группа дублируется, выберите другую Компанию или удалите поле",

    "Сочетание Подразделение/Должность дублируется, выберите другое Должность или удалите поле":
      "Сочетание Компания/Учебная группа дублируется, выберите другую Учебную группу или удалите поле",

    "Сотрудник с подразделением/должностью":
      "Сотрудник с компанией/учебной группой",
    "Сотрудник без подразделения/должности":
      "Сотрудник без компании/учебной группы",
    "Назначение повторного обучения": "Назначение повторного обучения",

    "Статистика обучения по подразделениям": "Статистика обучения по компаниям",

    "Ошибка получения подразделений": "Ошибка получения компаний",
    "Ошибка добавления подразделений": "Ошибка добавления компаний",

    "Добавить подразделение": "Добавить компанию",
  },
};

export default delovoy;
