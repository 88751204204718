import React from "react";
import { ProFormDateTimeRangePicker } from "@ant-design/pro-components";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import MemberSelect from "@/entities/member/ui/select";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import { useTranslation } from "react-i18next";

const CompanyPersonnelChangesFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <ProFormDateTimeRangePicker
        label="Дата назначения"
        name={["range", "created_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
      <ProFormDateTimeRangePicker
        label="Дата увольнения"
        name={["range", "deleted_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default CompanyPersonnelChangesFormFields;
