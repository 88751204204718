import React from "react";
import { Button, Flex, message, theme } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Department, Position, TreeNode } from "@/models";
import { ProForm, ProFormProps, ProFormText } from "@ant-design/pro-components";
import axios from "@/axios";
import styled from "styled-components";
import { deepmerge } from "deepmerge-ts";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { useTranslation } from "react-i18next";
import OrgStructureIcon from "@/entities/org-structure/ui/icon";

type OrgStructureEditFormProps = ProFormProps & {
  type: "department" | "position";
  rest:
    | {
        type: "update";
        recordKey: TreeNode["id"];
        value: TreeNode["is_an"]["name"];
        onAfterUpdate?: () => void;
      }
    | {
        type: "create";
        onAfterCreate?: () => void;
        parentTreeNodeId?: TreeNode["parent_tree_node_id"];
        onCancel?: () => void;
      };
};

const FormStyledWrapper = styled.div`
  .ant-form-item {
    margin-block: 4px;
  }
`;

const OrgStructureEditForm: React.FC<OrgStructureEditFormProps> = ({
  type,
  rest,
  ...props
}) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [form] = ProForm.useForm<Department | Position>();

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      initialValues: {
        name: rest.value,
      },
    });
    defaultProps.onFinish = async (values) => {
      switch (type) {
        case "department": {
          return await axios
            .put(`/api/departments/${rest.recordKey}`, {
              name: values.name,
            })
            .then(() => {
              message.success(t("Подразделение успешно обновлено"));
              rest.onAfterUpdate?.();
            })
            .catch((err) => {
              message.error(
                err.response.data.message ??
                  t("Не удалось обновить подразделение"),
              );
            });
        }

        case "position": {
          return await axios
            .put(`/api/positions/${rest.recordKey}`, {
              name: values.name,
            })
            .then(() => {
              message.success(t("Должность успешно обновлена"));
              rest.onAfterUpdate?.();
            })
            .catch((err) => {
              message.error(
                err.response.data.message ?? t("Не удалось обновить должность"),
              );
            });
        }

        default: {
          return false;
        }
      }
    };
  }

  /** REST Type Update */

  if (rest.type === "create") {
    const reBindContentToTree = async (embed: Department | Position) => {
      const embedTreeNode: TreeNode = await axios
        .get<OrionRestShowResponse<typeof embed>>(`/api/${type}s/${embed.id}`, {
          params: {
            include: "tree_node",
          },
        })
        //@ts-ignore
        .then((res) => res.data.data.tree_node);

      const parentTreeNodeChildren = await axios
        .get<OrionRestShowResponse<TreeNode>>(
          `/api/tree-nodes/${rest.parentTreeNodeId}`,
          {
            params: {
              include: "children",
            },
          },
        )
        .then((res) => res.data.data.children);

      await axios.put(`/api/tree-nodes/${embedTreeNode.id}`, {
        parent_tree_node_id: rest.parentTreeNodeId,
        order_index:
          parentTreeNodeChildren && parentTreeNodeChildren.length > 0
            ? parentTreeNodeChildren.length
            : 0,
      });
    };
    defaultProps.onFinish = async (values) => {
      switch (type) {
        case "department": {
          return await axios
            .post(`/api/departments`, values)
            .then(async ({ data: { data: department } }) => {
              message.success(t("Подразделение успешно cоздано"));
              if (rest.parentTreeNodeId) {
                await reBindContentToTree(department);
              }
              rest.onAfterCreate?.();
            })
            .catch((err) => {
              message.error(
                err.response.data.message ??
                  t("Не удалось создать подразделение"),
              );
            });
        }

        case "position": {
          return await axios
            .post(`/api/positions`, values)
            .then(async ({ data: { data: department } }) => {
              message.success(t("Должность успешно cоздана"));
              if (rest.parentTreeNodeId) {
                await reBindContentToTree(department);
              }
              rest.onAfterCreate?.();
            })
            .catch((err) => {
              message.error(
                err.response.data.message ?? t("Не удалось создать должность"),
              );
            });
        }

        default: {
          return false;
        }
      }
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <FormStyledWrapper>
      <ProForm {...props} style={{ width: "100%" }} grid>
        <Flex
          gap={token.paddingXXS}
          style={{ width: "100%" }}
          justify="space-between"
        >
          <ProFormText
            name={"name"}
            rules={[{ required: true }]}
            colProps={{ span: 24 }}
            fieldProps={{
              prefix: (
                <OrgStructureIcon
                  style={{ marginRight: token.marginXXS }}
                  type={type}
                />
              ),
              autoFocus: true,
              style: { borderColor: token.colorPrimaryHover },
              allowClear: false,
              suffix: (
                <Flex gap={token.paddingXXS}>
                  <Button
                    size="small"
                    icon={<CheckOutlined />}
                    type="text"
                    onClick={() => form.submit()}
                  />
                  <Button
                    size="small"
                    icon={<CloseOutlined />}
                    type="text"
                    onClick={() =>
                      rest.type === "update"
                        ? rest.onAfterUpdate?.()
                        : rest.onCancel?.()
                    }
                  />
                </Flex>
              ),
            }}
          />
        </Flex>
      </ProForm>
    </FormStyledWrapper>
  );
};

export default OrgStructureEditForm;
