import React from "react";
import { Flex, FlexProps, message, theme, Typography } from "antd";
import copy from "copy-to-clipboard";
import useSpace from "@/entities/space/lib/use";

type Props = {
  align?: FlexProps["align"];
};

const Support: React.FC<Props> = ({ ...props }) => {
  const space = useSpace();
  const { token } = theme.useToken();

  return (
    <Flex vertical align={props.align}>
      <Typography.Text style={{ fontSize: token.fontSizeSM }}>
        Поддержка:
      </Typography.Text>
      {space.support_phone_number && (
        <Typography.Link
          href={`tel:${space.support_phone_number}`}
          style={{
            fontSize: token.fontSizeSM,
            color: token.colorText,
          }}
        >
          {space.support_phone_number}
        </Typography.Link>
      )}
      {space.support_email && (
        <Typography.Link
          style={{
            fontSize: token.fontSizeSM,
            color: token.colorText,
          }}
          onClick={() => {
            copy(space.support_email);
            message.success("E-mail скопирован");
          }}
        >
          {space.support_email}
        </Typography.Link>
      )}
    </Flex>
  );
};

export default Support;
