import React, { useState } from "react";
import {
  ProForm,
  ProFormProps,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { Course, File } from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { Alert, message, theme } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useFeatures from "@/entities/features/lib/use.ts";
import { UploadFile } from "antd/lib";
import FilePreviewModal from "@/entities/file/ui/preview-modal";
import { FilePreviewProps } from "@/entities/file/ui/preview";
import UploadDraggerWithPreview from "@/entities/file/ui/upload-with-preview";

type FormData = Omit<Course, "cover_image_file"> & {
  cover_image_file?: (File & UploadFile)[] | null;
};

type CourseFormProps = ProFormProps<FormData> & {
  rest: RestProps<Course>;
};

const CourseForm: React.FC<CourseFormProps> = ({ rest, ...props }) => {
  const features = useFeatures();
  const [form] = ProForm.useForm<FormData>(props.form);
  const [toRemoveFileIds, setToRemoveFileIds] = useState<number[]>([]);
  const { token } = theme.useToken();
  const [isVisibleAlert, setIsVisibleAlert] = useState(false);

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: { style: { display: "none" } },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  const onRemove = (file: any) => {
    if (file.id) setToRemoveFileIds((prev) => [...prev, file.id]);
  };

  const syncFiles = async (formData: FormData) => {
    if (formData.cover_image_file) {
      if (formData.cover_image_file.length > 1) {
        throw new Error("Only one image file is allowed");
      }

      if (formData.cover_image_file.length === 1) {
        const imageFile = formData.cover_image_file[0];
        if (!imageFile.id) {
          await axios
            .post<OrionRestCreateResponse<File>>(`/api/files`, {
              key: imageFile.response.key,
              name: imageFile.name,
              type: imageFile.type,
              attached_to_id: formData.id,
              attached_to_type: "course",
            })
            .then((res) => res.data.data)
            .then((file) => {
              return axios.put<OrionRestUpdateResponse<Course>>(
                `/api/courses/${formData.id}`,
                { cover_image_file_id: file.id },
              );
            });
        }
      }
    }

    if (toRemoveFileIds.length > 0) {
      await axios
        .delete("/api/files/batch", {
          data: { resources: toRemoveFileIds },
        })
        .then(() => {
          setToRemoveFileIds([]);
        });
    }
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Создать" } },
    });
    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<Course>>("/api/courses", values)
        .then(async (res) => {
          await syncFiles({ ...values, id: res.data.data.id });
          return res.data.data;
        })
        .then((data) => {
          message.success("Курс успешно создан");
          rest.onAfterCreate?.(data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании курса",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Сохранить" } },
    });
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Course>>(
          `/api/courses/${rest.recordKey}?include=cover_image_file`,
        )
        .then((res) => {
          const data = res.data.data;
          return {
            ...data,
            cover_image_file: data.cover_image_file
              ? [{ ...data.cover_image_file, status: "done" }]
              : [],
          } as FormData;
        });
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<Course>>(
          `/api/courses/${rest.recordKey}`,
          values,
        )
        .then(async (res) => {
          await syncFiles({ ...values, id: res.data.data.id });
          return res.data.data;
        })
        .then((data) => {
          message.success("Курс успешно обновлён");
          rest.onAfterUpdate?.(data);

          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении курса: ${messageText}`);

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <ProForm<FormData> {...props}>
      <ProFormText
        name="name"
        label="Название"
        rules={[{ required: true, max: 255 }]}
      />
      {features.isEnabled("courses_keys") && (
        <ProFormText
          name="key"
          label="Идентификатор"
          rules={[
            { max: 100 },
            {
              pattern: /^[^\s]+$/,
              message: "Идентификатор не должен содержать пробелы",
            },
          ]}
        />
      )}
      {rest.type === "update" && (
        <>
          <ProFormSelect
            label="Статус"
            name="status"
            rules={[{ required: true }]}
            options={[
              { value: "active", label: "Активный" },
              { value: "archival", label: "Архивный" },
            ]}
            onChange={(value) => {
              if (value === "archival") {
                setIsVisibleAlert(true);
              } else {
                setIsVisibleAlert(false);
              }
            }}
          />
          {isVisibleAlert && (
            <Alert
              showIcon
              style={{ marginBottom: token.margin }}
              type="warning"
              message="Все Потоки будут завершены"
            />
          )}
        </>
      )}
      <ProFormTextArea name="description" label="Описание" />
      <UploadDraggerWithPreview
        label="Обложка"
        name="cover_image_file"
        title="Загрузите файл"
        description="Перетащите файл в эту область или нажмите на нее"
        max={1}
        rules={[
          { required: false },
          () => ({
            validator(_, files) {
              if (files) {
                for (const file of files) {
                  const oneMb = 1048000;
                  if (file.size > oneMb) {
                    return Promise.reject(
                      new Error("Необходимо выбрать файл меньше 1 МБ"),
                    );
                  }

                  if (!file.type) {
                    return Promise.reject(
                      new Error("Не удалось определить тип файла"),
                    );
                  }

                  const isImage = [
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                  ].includes(file.type);

                  if (!isImage) {
                    return Promise.reject(
                      new Error(
                        "Недопустимый тип файла: Можно загружать файлы с расширением PNG, JPG или JPEG",
                      ),
                    );
                  }
                }
              }
              return Promise.resolve();
            },
          }),
        ]}
        fieldProps={{
          listType: "picture",
          onRemove,
          accept: ".png, .jpg, .jpeg",
        }}
      />
    </ProForm>
  );
};

export default CourseForm;
export type { CourseFormProps };
