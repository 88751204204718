import React from "react";
import {
  BookOutlined,
  SettingOutlined,
  ShareAltOutlined,
  NodeIndexOutlined,
  UserOutlined,
  FileTextOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import useMe from "@/entities/me/lib/use";
import TestIcon from "@/entities/test/ui/icon";
import NavigationMenu, {
  NavigationMenuProps,
} from "@/shared/navigation-menu/ui";
import { Tooltip } from "antd";

const addKeys = (items: ItemType[]): ItemType[] => {
  return items.map((item) => {
    if (!item.children) {
      item.key = item.path;
    }

    if (item.children) {
      item.key = item.children.reduce((prev, curr) => prev + curr.path, "");
      item.children = addKeys(item.children);
    }

    return item;
  });
};

type ItemType = Omit<NavigationMenuProps["items"][number], "children"> & {
  hidden: boolean;
  children?: ItemType[];
};

const Menu: React.FC = () => {
  const member = useMe();

  const items: ItemType[] = [
    {
      path: "/manage/courses",
      label: "Курсы",
      icon: <BookOutlined />,
      hidden: !member.permissions.includes("course:view"),
    },
    {
      path: "/manage/tests",
      label: "Тесты",
      icon: <TestIcon />,
      hidden: !member.permissions.includes("test_participant:view"),
    },
    {
      label: "Пользователи",
      icon: <UserOutlined />,
      hidden: !member.permissions.some(
        (permission) => permission === "member:view",
      ),
      children: [
        {
          label: (
            <Tooltip title="Список пользователей" placement="right">
              Список пользователей
            </Tooltip>
          ),
          path: "/manage/members",
          hidden: !member.permissions.includes("member:view"),
        },
        {
          label: "Группы",
          path: "/manage/groups",
          hidden: !member.permissions.includes("member:view"),
        },
      ],
    },
    {
      label: "Потоки",
      icon: <UsergroupDeleteOutlined />,
      hidden: !member.permissions.some(
        (permission) => permission === "pool:view",
      ),
      children: [
        {
          label: "Список потоков",
          path: "/manage/pools",
          hidden: !member.permissions.includes("pool:view"),
        },
        {
          label: "Шаблоны потоков",
          path: "/manage/pool-templates",
          hidden: !member.permissions.includes("pool:view"),
        },
      ],
    },
    {
      path: "/manage/learning-matrix",
      label: "Матрица обучения",
      icon: <ShareAltOutlined />,
      hidden: !member.permissions.includes("learning_matrix_entry:view"),
    },
    {
      path: "/manage/automations",
      label: (
        <Tooltip title="Автоназначение обучения" placement="right">
          Автоназначение обучения
        </Tooltip>
      ),
      icon: <NodeIndexOutlined />,
      hidden: !member.permissions.includes("automation:view"),
    },
    {
      label: "Отчеты",
      icon: <FileTextOutlined />,
      hidden: !member.permissions.some(
        (permission) =>
          permission === "report:view" || permission === "dashboard:using",
      ),
      children: [
        {
          path: "/manage/report",
          label: "Отчеты",
          hidden: !member.permissions.includes("report:view"),
        },
        {
          path: "/manage/dashboards",
          label: "Дашборд",
          hidden: !member.permissions.includes("dashboard:using"),
        },
      ],
    },
    {
      label: "Настройки",
      icon: <SettingOutlined />,
      hidden: !member.permissions.some(
        (permission) =>
          permission === "space:view" ||
          permission === "license:view" ||
          permission === "role:view" ||
          permission === "service_account:view" ||
          permission === "member:view" ||
          permission === "org_structure:view",
      ),
      children: [
        {
          path: "/manage/license",
          label: "Лицензия",
          hidden: !member.permissions.includes("license:view"),
        },
        {
          path: "/manage/service-accounts",
          label: "Интеграции",
          hidden: !member.permissions.includes("service_account:view"),
        },
        {
          label: "Пространство",
          path: "/manage/space-config",
          hidden: !member.permissions.includes("space:view"),
        },
        {
          label: "Роли",
          path: "/manage/roles",
          hidden: !member.permissions.includes("role:view"),
        },
        {
          path: "/manage/space-info",
          label: (
            <Tooltip title="Информация о компании" placement="right">
              Информация о компании
            </Tooltip>
          ),
          hidden: !member.permissions.includes("space:view"),
        },
        {
          path: "/manage/org-structure",
          label: (
            <Tooltip title="Организационная структура" placement="right">
              Организационная структура
            </Tooltip>
          ),
          hidden: !member.permissions.includes("org_structure:view"),
        },
      ],
    },
  ]
    .flatMap((item) => {
      return addKeys([item as any]);
    })
    // RBAC
    .flatMap((item) => {
      if (!item.hidden) {
        if (item.children) {
          item.children = item.children.filter((item) => {
            if (!item.hidden) {
              return true;
            }
            return false;
          });
        }
        return [item];
      }
      return [];
    });

  return (
    <NavigationMenu
      style={{ height: "100%", width: "100%" }}
      items={items as NavigationMenuProps["items"]}
      mode={"inline"}
    />
  );
};

export default Menu;
