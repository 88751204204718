import React from "react";
import {
  Button,
  Dropdown,
  Flex,
  MenuProps,
  message,
  theme,
  Typography,
} from "antd";
import copy from "copy-to-clipboard";
import { DownOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import useSpace from "@/entities/space/lib/use";

type Props = {};

const SupportDropdown: React.FC<Props> = ({}) => {
  const space = useSpace();
  const { token } = theme.useToken();

  const items: MenuProps["items"] = [
    space.support_phone_number
      ? {
          label: (
            <Typography.Link href={`tel:${space.support_phone_number}`}>
              <Flex gap={token.paddingXS}>
                <PhoneOutlined />
                {space.support_phone_number}
              </Flex>
            </Typography.Link>
          ),
          key: "phone",
        }
      : null,
    space.support_email
      ? {
          label: (
            <Typography.Link>
              <Flex gap={token.paddingXS}>
                <MailOutlined />
                {space.support_email}
              </Flex>
            </Typography.Link>
          ),
          key: "email",
          onClick: () => {
            copy(space.support_email);
            message.success("E-mail скопирован");
          },
        }
      : null,
  ];

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      menu={{ items: items }}
    >
      <Button type="text">
        Поддержка <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default SupportDropdown;
