import {
  ProFormUploadButton,
  ProFormUploadButtonProps,
  ProFormUploadDragger,
  ProFormUploadDraggerProps,
} from "@ant-design/pro-components";
import FilePreviewModal from "./preview-modal";
import { useState } from "react";
import { FilePreviewProps } from "./preview";
import uploadRequest from "../lib/upload-request";
import axios from "@/axios";
import fileDownload from "js-file-download";
import { message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

type Props = (ProFormUploadDraggerProps | ProFormUploadButtonProps) & {
  type?: "dragger" | "button";
};

const UploadWithPreview: React.FC<Props> = ({
  fieldProps,
  type = "dragger",
  ...props
}) => {
  const [preview, setPreview] = useState<FilePreviewProps | undefined>(
    undefined,
  );

  const Upload =
    type === "dragger" ? ProFormUploadDragger : ProFormUploadButton;

  const onPreview = async (file: any) => {
    const type = file.type.split("/");
    if (type[0] === "image" || type[1] === "pdf" || type[0] === "video") {
      setPreview(
        file.originFileObj
          ? {
              file: {
                type: file.type,
                url: URL.createObjectURL(file.originFileObj),
              },
            }
          : { file },
      );
    } else {
      message.warning("Просмотр данного типа файла не поддерживается");
    }
  };

  return (
    <>
      <Upload
        fieldProps={{
          customRequest: uploadRequest,
          onDownload: (file) => {
            if (!file.url) return;
            axios
              .get(file.url!, { responseType: "blob" })
              .then((response) => {
                fileDownload(response.data, file.name);
              })
              .catch(() => {
                message.error("Ошибка загрузки файла");
              });
          },
          onPreview,
          showUploadList: {
            showPreviewIcon: false,
            showRemoveIcon: true,
            showDownloadIcon: true,
            downloadIcon: (file) => {
              if (!file.url) {
                file.status = undefined;
              }
              return <DownloadOutlined />;
            },
          },
          ...fieldProps,
        }}
        {...props}
      />
      <FilePreviewModal
        open={preview !== undefined}
        onCancel={() => setPreview(undefined)}
        previewProps={preview!}
      />
    </>
  );
};

export default UploadWithPreview;
