import React from "react";
import { Link, useParams } from "react-router-dom";
import PoolParticipantTable from "@/entities/pool-participant/ui/table";
import { Flex } from "antd";
import { ActionType } from "@ant-design/pro-table/es/typing";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useMe from "@/entities/me/lib/use";
import useFeatures from "@/entities/features/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";

const Page: React.FC = () => {
  const { pool_id } = useParams();
  const features = useFeatures();
  const { parentHeight, ref } = useParentHeight("table");

  const member = useMe();

  let actionRef = React.useRef<ActionType>();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <div ref={ref} style={{ height: "100%" }}>
        <PoolParticipantTable
          hasQueryParams
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          columnsState={{
            persistenceKey: "pool-participant-table",
          }}
          poolId={Number(pool_id!)}
          scroll={{ y: parentHeight - 192 }}
          actionRef={actionRef}
          columns={[
            {
              dataIndex: ["member", "full_name"],
              render: (node, { member: { id } }) => {
                if (member.permissions.includes("member:view")) {
                  return (
                    <Link to={`/manage/members/${id}?tab=pools`}>{node}</Link>
                  );
                }
                return node;
              },
              hideInSetting: true,
              disable: true,
            },
            { dataIndex: "registry_number" },
            { dataIndex: "status" },
            { dataIndex: "progress_percent" },
            {
              dataIndex: ["member", "positions", "position_id"],
              hideInTable: !features.isEnabled(
                "show_department_and_position_in_pool_participants_table",
              ),
              hideInSearch: !features.isEnabled(
                "show_department_and_position_in_pool_participants_table",
              ),
            },
            { dataIndex: "starts_at" },
            { dataIndex: "started_at" },
            { dataIndex: "ends_at" },
            { dataIndex: "ended_at" },
          ]}
        />
      </div>
    </Flex>
  );
};

export default Page;
