import React, { useRef } from "react";
import { Button } from "antd";
import dayjs from "dayjs";
import { Pool } from "@/models";
import {
  ProFormDateTimePicker,
  ProFormProps,
} from "@ant-design/pro-components";
import { toISOString } from "@/shared/dayjs/lib/formats";

type DateTimePickerWithTimeControlsProps = React.ComponentProps<
  typeof ProFormDateTimePicker
> & {
  form: Required<ProFormProps>["form"];
};

const DateTimePickerWithTimeControls: React.FC<
  DateTimePickerWithTimeControlsProps
> = ({ form, fieldProps, ...props }) => {
  const selectedDate = useRef<dayjs.Dayjs>();

  const onClick = (mode: "start" | "end") => {
    let currentSelectedDate;
    if (selectedDate.current) {
      currentSelectedDate = selectedDate.current;
    } else if (form.getFieldValue(props.name)) {
      currentSelectedDate = dayjs(form.getFieldValue(props.name));
    } else {
      currentSelectedDate = dayjs();
    }

    const hour = mode === "start" ? 0 : 23;
    const minute = mode === "start" ? 0 : 59;
    const startOfDay = currentSelectedDate
      ?.set("hour", hour)
      .set("minute", minute);

    form.setFieldsValue({
      [props.name]: toISOString(startOfDay),
    });
  };

  return (
    <ProFormDateTimePicker
      fieldProps={{
        onSelect(value) {
          selectedDate.current = value;
        },
        renderExtraFooter: () => {
          return (
            <>
              <Button
                type="link"
                onClick={() => {
                  selectedDate.current = dayjs();
                  form.setFieldValue(props.name, toISOString(dayjs()));
                }}
              >
                Сейчас
              </Button>
              <Button type="link" onClick={() => onClick("start")}>
                Начало дня
              </Button>
              <Button type="link" onClick={() => onClick("end")}>
                Конец дня
              </Button>
            </>
          );
        },
        ...fieldProps,
      }}
      {...props}
    />
  );
};

export default DateTimePickerWithTimeControls;
