import React from "react";
import MembersTable from "@/entities/member/ui/table";
import { Link } from "react-router-dom";
import { Flex, Space, Typography } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import useMe from "@/entities/me/lib/use";
import useSpace from "@/entities/space/lib/use";

const Page: React.FC = () => {
  const member = useMe();
  const space = useSpace();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <MembersTable
        style={{
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px)",
        }}
        hasQueryParams
        scroll={{ x: "max-content" }}
        options={{
          setting: {
            draggable: false,
            showListItemOption: false,
          },
          reload: false,
          density: false,
        }}
        columnsState={{
          persistenceKey: "members-table",
          defaultValue: {
            full_name: { show: true, fixed: "left" },
            role_id: { show: false },
            email: {
              show: space.authentication_types.includes("email+password"),
            },
            username: {
              show: space.authentication_types.includes("username+password"),
            },
            "positions,position_id": { show: true },
            status: { show: false },
            "groups,id": { show: true },
            created_at: { show: true },
          },
        }}
        columns={[
          {
            dataIndex: "full_name",
            render: (dom, { id }) => {
              if (member.permissions.includes("member:view")) {
                return <Link to={`/manage/members/${id}`}>{dom}</Link>;
              }
              return dom;
            },
            fixed: "left",
            hideInSetting: true,
            disable: true,
          },
          { dataIndex: "role_id", width: 150 },
          { dataIndex: "email" },
          { dataIndex: "username" },
          { dataIndex: "status", width: 150 },
          {
            dataIndex: ["positions", "position_id"],
            width: 200,
          },
          {
            dataIndex: ["groups", "id"],
            width: 200,
            render: (_, { groups }) => {
              if (groups && groups.length === 0) {
                return "-";
              }
              return (
                <Typography.Text ellipsis={{ tooltip: true }}>
                  {groups?.map((el, index) => (
                    <Link
                      key={`${el.id}-position`}
                      to={`/manage/groups/${el.id}`}
                    >
                      {el.name ? el.name : "-"}
                      {index !== groups.length - 1 ? ", " : ""}
                    </Link>
                  ))}
                </Typography.Text>
              );
            },
            hideInSetting: true,
            disable: true,
          },
          { dataIndex: "created_at" },
        ]}
      />
    </Flex>
  );
};

export default Page;
