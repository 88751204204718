import React from "react";
import { Course, Member, Pool, PoolParticipant } from "@/models";
import axios from "@/axios";
import { AxiosRequestConfig } from "axios";
import {
  OrionRestIndexResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import PoolParticipantModalFormCreate, {
  PoolParticipantModalFormCreateProps,
} from "@/entities/pool-participant/ui/modal-form-create";
import PoolParticipantRemoveButton, {
  PoolParticipantRemoveButtonProps,
} from "@/entities/pool-participant/ui/button-remove";
import { ActionType } from "@ant-design/pro-table/es/typing";
import PoolParticipantBatchUpdateModalForm, {
  PoolParticipantBatchUpdateModalFormProps,
} from "@/entities/pool-participant/ui/modal-form-update-batch";
import { deepmerge } from "deepmerge-ts";
import {
  Button,
  Dropdown,
  message,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import useSWR from "swr";
import Spin from "@/shared/components/spin";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import ExportButton from "@/entities/export/ui/button";
import ImportButton from "@/entities/import/ui/button";
import statuses from "@/entities/pool-participant/lib/statuses.tsx";
import PoolModalForm from "@/entities/pool/ui/modal-form.tsx";
import { statusValueEnum } from "@/entities/member/lib/status-value-enum";
import arraysEqual from "@/shared/helpers/arrays-equal";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { useTranslation } from "react-i18next";
import { invertColor } from "@/shared/helpers/invert-hex-color";
import {
  ProFormDateTimeRangePicker,
  ProFormDigitRange,
  ProFormSelect,
} from "@ant-design/pro-components";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import ArchivalCourseIcon from "@/entities/course/ui/archival-icon";
import getTreeNodeNames from "@/entities/member/lib/get-tree-node-names";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import CourseSearchSelect from "@/entities/course/ui/select";
import PoolSelect from "@/entities/pool/ui/select";
import { uniq } from "lodash";

type Record = Omit<PoolParticipant, "pool" | "member"> & {
  pool: Omit<Pool, "course"> & {
    course: Course;
  };
  member: Member;
  filters: any[];
};

type Params = Partial<Record>;

type TableProps = ProTableProps<Record, Params>;

type PoolParticipantTableProps = TableProps & {
  poolId?: Pool["id"];
  memberId?: Member["id"];
  toolBarActionsProps?: {
    create?: PoolParticipantModalFormCreateProps;
    batchUpdate?: PoolParticipantBatchUpdateModalFormProps;
    remove?: PoolParticipantRemoveButtonProps;
  };
};

const PoolParticipantTable: React.FC<PoolParticipantTableProps> = ({
  poolId,
  memberId,
  actionRef: actionRefProp,
  ...tableProps
}) => {
  if (typeof actionRefProp === "function") {
    throw new Error(
      "actionRef as function is not supported. Please use useRef instead",
    );
  }
  const member = useMe();

  const { t } = useTranslation();

  let actionRef = React.useRef<ActionType | undefined | null>();

  if (actionRefProp) {
    actionRef = actionRefProp;
  }

  const { parentHeight, ref } = useParentHeight("table");

  const {
    data: pool,
    isLoading,
    error,
    mutate: mutatePool,
  } = useSWR(poolId ? `/api/pools/${poolId}?include=course` : null, (url) =>
    axios.get<OrionRestShowResponse<Pool>>(url).then((res) => res.data.data),
  );

  const isArchivedCourse = () => {
    if (pool?.course?.status === "archival") {
      message.error(
        "Данное действие недоступно для потока со статусом Архивный",
      );
      return true;
    }
    return false;
  };

  const toolBarRender: TableProps["toolBarRender"] = (
    _,
    { selectedRowKeys },
  ) => [
    member.permissions.includes("pool_participant:delete") && (
      <PoolParticipantRemoveButton
        key={"remove"}
        {...deepmerge(
          {
            poolParticipantsIds: selectedRowKeys?.map(Number),
            afterRemove: () => {
              actionRef.current?.clearSelected?.();
              actionRef.current?.reload();
            },
          } as PoolParticipantRemoveButtonProps,
          tableProps?.toolBarActionsProps?.remove ?? {},
        )}
      />
    ),
    member.permissions.includes("pool_participant:update") && (
      <PoolParticipantBatchUpdateModalForm
        key={"batch-update"}
        onAfterUpdate={isArchivedCourse}
        {...(deepmerge(
          {
            formProps: {
              initialValues: { pool_id: poolId },
              poolParticipantsIds: selectedRowKeys?.map((key) => Number(key)),
              afterUpdate: () => actionRef.current?.reload(),
            },
            triggerProps: { disabled: selectedRowKeys?.length === 0 },
          } as PoolParticipantBatchUpdateModalFormProps,
          tableProps?.toolBarActionsProps?.batchUpdate ?? {},
        ) as PoolParticipantBatchUpdateModalFormProps)}
      />
    ),
    poolId && member.permissions.includes("pool:update") && (
      <PoolModalForm
        key={`update`}
        isArchivedCourse={isArchivedCourse}
        courseId={pool!.course_id}
        rest={{
          type: "update",
          recordKey: poolId,
          onAfterUpdate: () => {
            actionRef.current?.reload();
          },
        }}
      />
    ),
    poolId && pool!.is_generates_documents && (
      <Dropdown
        menu={{
          items: [
            ...(member.permissions.includes("export:export_command")
              ? [
                  {
                    label: (
                      <ExportButton
                        metaFields={{
                          pool_id: poolId,
                        }}
                        type="export_command"
                        trigger={<a>Выгрузить Приказ</a>}
                      />
                    ),
                    key: "export-command",
                  },
                ]
              : []),
            ...(member.permissions.includes("export:export_protocol")
              ? [
                  {
                    label: (
                      <ExportButton
                        metaFields={{
                          pool_id: poolId,
                        }}
                        type="export_protocol"
                        trigger={<a>Выгрузить Протокол</a>}
                      />
                    ),
                    key: "export-protocol",
                  },
                ]
              : []),
            ...(member.permissions.includes("export:export_for_registry")
              ? [
                  {
                    label: (
                      <ExportButton
                        metaFields={{
                          pool_id: poolId,
                        }}
                        type="export_for_registry"
                        trigger={<a>Выгрузить для Реестра</a>}
                      />
                    ),
                    key: "export-for-registry",
                  },
                ]
              : []),
            ...(member.permissions.includes("import:registry")
              ? [
                  {
                    label: (
                      <ImportButton
                        type="registry"
                        poolId={poolId}
                        onAfterFinish={() => actionRef.current?.reload()}
                        trigger={<a>Загрузить из Реестра</a>}
                      />
                    ),
                    key: "import-from-registry",
                  },
                ]
              : []),
          ],
        }}
      >
        <Button>
          <Space>
            Документы
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    ),
    member.permissions.includes("pool_participant:create") && (
      <PoolParticipantModalFormCreate
        onAfterCreate={isArchivedCourse}
        key={"create"}
        {...deepmerge(
          {
            formProps: {
              fieldsProps: { poolId: { hidden: true } },
              initialValues: { pool_id: poolId },
              afterCreate: () => actionRef.current?.reload(),
            },
          },
          tableProps?.toolBarActionsProps?.create ?? {},
        )}
      />
    ),
  ];

  const defaultTableProps: TableProps = {
    rowKey: "id",
    toolBarRender,
    pagination: {
      showSizeChanger: true,
    },
    rowSelection: {},
    options: false,
    style: {
      width: "calc(100vw - 240px)",
      maxWidth: "calc(1920px - 240px)",
    },
    scroll: { y: parentHeight - 223, x: 1200 },
    request: async (params, sort, filter) => {
      await mutatePool();

      const { ["member,positions,position_id"]: positionIds, ...filters } =
        filter;

      if (sort === undefined || Object.keys(sort).length === 0) {
        sort = { created_at: "descend" };
      }

      const config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/pool-participants/search",
        ...axiosConfigAdapter(params, sort, filters),
      };

      if (positionIds) {
        const positionsIdsFlat = positionIds.flatMap((v) => {
          if (typeof v === "string") {
            return v.split(",").reduce((acc, id) => {
              if (!isNaN(Number(id))) acc.push(Number(id));
              return acc;
            }, [] as number[]);
          }
          return [v];
        });

        const uniquePositionsIds = uniq(positionsIdsFlat);

        config.data.filters.push({
          type: "and",
          nested: uniquePositionsIds.map((id) => ({
            type: "or",
            field: "member.positions.position_id",
            operator: "=",
            value: id,
          })),
        });
      }

      if (params.filters) {
        config.data.filters.push(...params.filters);
      }

      config.data.includes = [
        { relation: "member" },
        { relation: "pool" },
        { relation: "pool.course" },
      ];

      if (poolId) {
        config.data.filters.push({
          field: "pool_id",
          operator: "=",
          value: poolId,
        });
      }

      if (memberId) {
        config.data.filters.push({
          field: "member_id",
          operator: "=",
          value: memberId,
        });
      }
      tableProps.columns?.forEach((column) => {
        if (
          Array.isArray(column.dataIndex) &&
          arraysEqual(column.dataIndex, ["member", "positions", "position_id"])
        ) {
          config.data.includes.push(
            { relation: "member.positions.position" },
            {
              relation:
                "member.positions.position.tree_node.reverse_tree.is_an",
            },
          );
        }

        if (
          Array.isArray(column.dataIndex) &&
          arraysEqual(column.dataIndex, ["member", "role_id"])
        ) {
          config.data.includes.push({
            relation: "member.role",
          });
        }
      });

      const data = await axios
        .request<OrionRestIndexResponse<Record>>(config)
        .then((res) => res.data);

      return {
        data: data.data,
        success: true,
        total: data.meta.total,
      };
    },
    columns: [
      {
        title: "Курс",
        dataIndex: ["pool", "course", "id"],
        renderText: (_, record) => {
          return (
            <>
              {record.pool.course.name}{" "}
              {record.pool.course.status === "archival" && (
                <ArchivalCourseIcon />
              )}
            </>
          );
        },

        filters: false,
        renderFormItem(_, config) {
          return (
            <CourseSearchSelect
              params={{ initialValue: config.value }}
              label={null}
              {...config}
              mode="multiple"
            />
          );
        },
      },
      {
        dataIndex: ["member", "role_id"],
        title: "Роль",
        render: (_, { member: { role } }) => (
          <Tag color={role.color} bordered>
            <Typography.Text
              ellipsis={{ tooltip: true }}
              style={{ color: invertColor(role.color), maxWidth: "120px" }}
            >
              {role.name}
            </Typography.Text>
          </Tag>
        ),
        hideInSearch: true,
      },
      {
        dataIndex: ["member", "status"],
        title: "Статус пользователя",
        valueType: "select",
        valueEnum: statusValueEnum,
        defaultFilteredValue: ["active"],
      },
      {
        title: "Поток",
        dataIndex: ["pool", "id"],
        renderText: (_, record) => {
          return <>{record.pool.name}</>;
        },
        filters: false,
        renderFormItem(_, config) {
          return (
            <PoolSelect
              params={{ initialValue: config.value }}
              label={null}
              {...config}
              mode="multiple"
            />
          );
        },
      },
      {
        title: "ФИО",
        dataIndex: ["member", "full_name"],
        sorter: true,
      },
      {
        title: () => "Номер в реестре",
        formItemProps: {
          tooltip: false,
        },
        tooltip: true,
        dataIndex: "registry_number",
        sorter: true,
        hideInTable: !(pool?.is_generates_documents ?? true),
        hideInSearch: !(pool?.is_generates_documents ?? true),
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueType: "select",
        valueEnum: statuses,
        filters: false,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: () => "Прогресс прохождения",
        tooltip: true,
        dataIndex: "progress_percent",
        render: (_, { progress_percent: percent }) =>
          `${Number(percent).toFixed(2)}%`,
        sorter: true,
        formItemProps: {
          name: ["range", "progress_percent"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDigitRange
              {...config}
              fieldProps={{ min: 0, max: 100 }}
              separator="-"
              placeholder={["от", "до"]}
            />
          );
        },
      },
      {
        title: () => "Планируемая дата начала",
        tooltip: true,
        dataIndex: "starts_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "starts_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
      {
        title: () => "Фактическая дата начала",
        tooltip: true,
        dataIndex: "started_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "started_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
      {
        title: () => "Планируемая дата завершения",
        tooltip: true,
        dataIndex: "ends_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "ends_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
      {
        title: () => "Фактическая дата завершения",
        tooltip: true,
        dataIndex: "ended_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "ended_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
      {
        title: t("Должность"),
        dataIndex: ["member", "positions", "position_id"],
        renderText: (_, { member: { positions } }) => {
          if (positions && positions.length === 0) {
            return "-";
          }

          const positionNames = positions?.map(({ position }) => {
            const name = position?.name;
            // @ts-ignore
            const treeNodeName = getTreeNodeNames(position?.tree_node);

            return name ? `${treeNodeName} ${name}` : "-";
          });

          return (
            <Tooltip
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={positionNames?.join(";\n")}
            >
              <Typography.Text ellipsis>
                {positionNames?.join("; ")}
              </Typography.Text>
            </Tooltip>
          );
        },
        renderFormItem(_, config) {
          return (
            <OrgStructureTreeSelect
              searchUser
              labelWithPath
              fieldProps={{ multiple: true }}
              {...config}
            />
          );
        },
      },
    ],
  };

  const overrideProps: TableProps = {
    actionRef,
  };

  tableProps = {
    ...deepmergeProTableProps(defaultTableProps as any, tableProps as any),
    ...overrideProps,
  };

  if (poolId && isLoading) return <Spin />;
  if (poolId && error) throw error;
  if (poolId && !pool) throw new Error("Pool not found");

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <ProTable<Record, Params> {...tableProps} />
    </div>
  );
};
export default PoolParticipantTable;
export type { PoolParticipantTableProps };
