import { ProFormDateRangePicker } from "@ant-design/pro-components";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import MemberSelect from "@/entities/member/ui/select";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import { useTranslation } from "react-i18next";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";

const LatestAddedMembersFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="single"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"id"}
      />
      <OrgStructureTreeSelect
        label={t("Должность")}
        name={["positions", "position_id"]}
        colProps={{ span: 8 }}
        labelWithPath
        searchUser
        fieldProps={{ multiple: true }}
      />
      <ProFormDateRangePicker
        label="Дата добавления в систему"
        colProps={{ span: 8 }}
        name={["range", "created_at"]}
        fieldProps={{ format: dateSTime }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default LatestAddedMembersFormFields;
