import React, { useState } from "react";
import {
  ProForm,
  ProFormItem,
  ProFormProps,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { DownOutlined } from "@ant-design/icons";
import {
  Role as BaseRole,
  Permission,
  RolePermission as BaseRolePermission,
  Import,
  Export,
} from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestIndexResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import {
  Button,
  Col,
  ColorPicker,
  Flex,
  message,
  Modal,
  Row,
  Space,
  Spin,
  theme,
} from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import type { ColorPickerProps } from "antd";
import CheckboxFormFieldPermission from "./checkbox-group-field";
import useSWR from "swr";
import RolesDeleteButton from "@/entities/roles/delete-button";

type Color = ColorPickerProps["value"];

type Role = Omit<BaseRole, "color"> & {
  color: Color;
  [key: string]: any;
};
type RolesFormProps = ProFormProps<Role> & {
  rest: RestProps<Role> & {
    onAfterCancel?: () => void;
    onAfterFinish?: (data: Role) => void;
  };
};

type RolePermission = BaseRolePermission & {
  key: string;
  permissionValue: string;
};

const RolesForm: React.FC<RolesFormProps> = ({ rest, ...props }) => {
  const [form] = ProForm.useForm<Role>(props.form);
  const { token } = theme.useToken();
  const [modal, modalHolder] = Modal.useModal();
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [permissionsIsLoading, setPermissionsIsLoading] =
    useState<boolean>(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] =
    useState<boolean>(false);

  const defaultColor = "#1677FF";
  const {
    data: permissions,
    isLoading,
    error,
  } = useSWR(
    "/api/permissions/search",
    (url) =>
      axios
        .post<OrionRestIndexResponse<Permission>>(
          url,
          {},
          { params: { limit: 150 } },
        )
        .then((res) => res.data.data),
    { revalidateOnFocus: false },
  );
  const prioritySectionOrder = [
    "org_structure",
    "role",
    "course",
    "member",
    "learning_matrix_entry",
    "license",
    "space",
    "service_account",
    "pool",
    "pool_participant",
    "automation",
    "test_participant",
    "report",
    "dashboard",
    "export",
    "import",
  ];

  const priorityPermissionOrder = [
    "view",
    "update",
    "create",
    "delete",
    "import",
    "export",
  ];

  function groupAndSortData(data: RolePermission[], sort: "asc" | "desc") {
    const groupedData = data.reduce((acc, record) => {
      const { key } = record;
      if (!acc[key]) {
        acc[key] = { key, records: [] };
      }
      acc[key].records.push(record);
      return acc;
    }, {} as { [key: string]: { key: string; records: RolePermission[] } });

    return Object.values(groupedData).map((group) => {
      group.records.sort((a, b) => {
        if (sort === "asc") {
          return (
            priorityPermissionOrder.indexOf(a.permissionValue) -
            priorityPermissionOrder.indexOf(b.permissionValue)
          );
        } else {
          return (
            priorityPermissionOrder.indexOf(b.permissionValue) -
            priorityPermissionOrder.indexOf(a.permissionValue)
          );
        }
      });
      return group;
    });
  }

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!permissions) throw new Error("Permissions not loaded");

  let defaultProps: Partial<typeof props> = {
    submitter: false,
    preserve: false,
    initialValues: {
      color: defaultColor,
    },
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  const fetchUpdateData = async (roleId: Role["id"]) => {
    const role = await axios
      .get<OrionRestShowResponse<Role>>(`/api/roles/${roleId}`)
      .then((res) => res.data.data);

    const rolePermissions = await axios
      .post<OrionRestIndexResponse<RolePermission>>(
        "/api/role-permissions/search",
        {
          filters: [{ field: "role_id", operator: "=", value: role.id }],
        },
        { params: { limit: 150 } },
      )
      .then((res) => res.data.data);
    const formPermissions: Record<string, string[]> = {};
    permissions.forEach((permission) => {
      if (rolePermissions.find((rp) => rp.permission_id === permission.id)) {
        const key = permission.key.split(":");
        if (!formPermissions[key[0]]) {
          formPermissions[key[0]] = [permission.key];
        } else {
          formPermissions[key[0]].push(permission.key);
        }
      }
    });

    if (formPermissions.import) {
      formPermissions.import.forEach((permission) => {
        const importType = permission.split(":")[1] as Import["type"];
        switch (importType) {
          case "course":
          case "questions":
            formPermissions.course.push(permission);
            break;
          case "learning_matrix":
            formPermissions.learning_matrix_entry.push(permission);
            break;
          case "members":
            formPermissions.member.push(permission);
            break;
          case "registry":
            formPermissions.pool_participant.push(permission);
            break;
          default:
            break;
        }
      });
      delete formPermissions.import;
    }

    if (formPermissions.export) {
      formPermissions.export.forEach((permission) => {
        const exportType = permission.split(":")[1] as Export["type"];
        switch (exportType) {
          case "export_learning_matrix":
            formPermissions.learning_matrix_entry.push(permission);
            break;
          case "export_members":
            formPermissions.member.push(permission);
            break;
          case "export_for_registry":
          case "export_command":
          case "export_protocol":
            formPermissions.pool_participant.push(permission);
            break;
          default:
            break;
        }
      });
      delete formPermissions.export;
    }

    return {
      ...role,
      ...formPermissions,
    };
  };

  defaultProps.onFinish = async (values) => {
    if (values.color && typeof values.color !== "string") {
      values.color = values.color.toHexString();
    }
    const { name, color, description, ...permissionValues } = values;
    let role: Role;

    setIsSubmitButtonLoading(true);

    if (rest.type === "create") {
      role = await axios
        .post<OrionRestCreateResponse<Role>>("/api/roles", {
          name,
          color,
          description,
          is_default: false,
          is_guarded: false,
        })
        .then((res) => {
          message.success("Роль успешно создана");
          return res.data.data;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании роли",
          );
          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }
          return Promise.reject(err);
        });
    } else {
      setPermissionsIsLoading(true);
      role = await axios
        .put<OrionRestUpdateResponse<Role>>(`/api/roles/${rest.recordKey}`, {
          name,
          color,
          description,
        })
        .then((res) => {
          message.success("Роль успешно обновлена");
          return res.data.data;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при обновленииы роли",
          );
          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }
          return Promise.reject(err);
        });
    }

    let rolePermissions: RolePermission[] = [];

    if (rest.type === "update") {
      rolePermissions = await axios
        .post<OrionRestIndexResponse<RolePermission>>(
          "/api/role-permissions/search",
          {
            filters: [
              { field: "role_id", operator: "=", value: rest.recordKey },
            ],
            includes: [{ relation: "permission" }],
          },
          { params: { limit: 150 } },
        )
        .then((res) => res.data.data);
    }

    const resourcesToCreate: RolePermission[] = [];
    const exsistingPermissionKey: string[] = [];

    for (const resource in permissionValues) {
      if (Array.isArray(permissionValues[resource]))
        for (const action of permissionValues[resource]) {
          const permission = permissions.find(
            (permission) => permission.key === action,
          );
          if (permission) {
            const existingRolePermission = rolePermissions.find(
              (rp) => action === rp.permission.key,
            );

            if (existingRolePermission) {
              exsistingPermissionKey.push(
                existingRolePermission.permission.key,
              );
            } else {
              resourcesToCreate.push({
                role_id: Number(role.id),
                permission_id: Number(permission.id),
                key: `${action.split(":")[0]}`,
                permissionValue: `${action.split(":")[1]}`,
              } as RolePermission);
            }
          }
        }
    }

    const resourcesToDelete = rolePermissions.flatMap((rolePermission) => {
      if (exsistingPermissionKey.includes(rolePermission.permission.key)) {
        return [];
      } else {
        rolePermission.key = rolePermission.permission.key.split(":")[0];
        rolePermission.permissionValue =
          rolePermission.permission.key.split(":")[1];
        return [rolePermission];
      }
    });
    const resourcesToDeleteBySort = groupAndSortData(resourcesToDelete, "desc")
      .sort(
        (a, b) =>
          prioritySectionOrder.indexOf(b.key) -
          prioritySectionOrder.indexOf(a.key),
      )
      .flatMap((group) => group.records);

    const resourcesToCreateBySort = groupAndSortData(
      resourcesToCreate,
      "asc",
    ).sort(
      (a, b) =>
        prioritySectionOrder.indexOf(a.key) -
        prioritySectionOrder.indexOf(b.key),
    );

    if (resourcesToDelete.length > 0) {
      for (const resource of resourcesToDeleteBySort) {
        await axios
          .delete(`/api/role-permissions/${resource.id}`)
          .catch((error) => {
            console.log(error);
            message.error(
              error.response.data.message ??
                "Ошибка при удалении Разрешения для роли",
            );
          });
      }

      message.success("Разрешения для роли успешно удалены");
    }

    if (resourcesToCreate.length > 0) {
      await axios
        .post<OrionRestCreateResponse<RolePermission>>(
          "/api/role-permissions/batch",
          {
            resources: resourcesToCreateBySort.flatMap((item) => item.records),
          },
        )
        .then((res) => {
          message.success("Разрешения для роли успешно добавлены");
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ??
              "Ошибка при добавлении разрешений для роли",
          );
          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }
          return false;
        });
    }

    if (rest.type === "update") {
      setPermissionsIsLoading(false);
    }
    rest.onAfterFinish?.(role);
    setIsSubmitButtonLoading(false);
    return Promise.resolve(true);
  };

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return await fetchUpdateData(Number(rest.recordKey));
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };
  const Submitter: React.FC = () => {
    const resetText = rest.type === "create" ? "создание" : "редактирование";
    return (
      <Flex justify={"end"} gap={token.padding} style={{ width: "100%" }}>
        {rest.type === "update" && (
          <RolesDeleteButton
            recordKey={Number(rest.recordKey)}
            onAfterDelete={() => rest.onAfterDelete?.()}
          />
        )}
        <Button
          onClick={() => {
            modal.confirm({
              title: `Отменить ${resetText} роли?`,
              onOk: () => rest.onAfterCancel?.(),
              okText: "Подтвердить",
            });
          }}
        >
          Отменить
        </Button>
        <Button
          type={"primary"}
          loading={isSubmitButtonLoading}
          onClick={() => {
            form.submit();
          }}
        >
          Сохранить
        </Button>
      </Flex>
    );
  };

  /** Render */

  return (
    <>
      {modalHolder}
      <Spin spinning={permissionsIsLoading}>
        <ProForm<Role> {...props}>
          <Submitter />
          <ProFormItem name="color" label="Цвет">
            <ColorPicker
              format={"hex"}
              open={openColorPicker}
              onOpenChange={setOpenColorPicker}
              disabledAlpha
              showText={() => (
                <DownOutlined
                  rotate={openColorPicker ? 180 : 0}
                  style={{
                    color: token.colorTextDisabled,
                  }}
                />
              )}
            />
          </ProFormItem>
          <ProFormText
            name="name"
            label="Название"
            rules={[{ required: true }]}
          />
          <ProFormTextArea name="description" label="Описание" />

          <Row>
            <Col span={8}>
              <Space direction="vertical">
                <CheckboxFormFieldPermission
                  options={[
                    { value: "course:view", label: "Просмотр курса" },
                    { value: "course:create", label: "Создание курсов" },
                    { value: "course:update", label: "Редактирование курсов" },
                    { value: "course:delete", label: "Удаление курсов" },
                    {
                      value: "import:course",
                      label: "Импорт курсов",
                    },
                    { value: "import:questions", label: "Импорт вопросов" },
                  ]}
                  name="course"
                  label="Курсы"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("course");
                    const test_participant =
                      form.getFieldValue("test_participant");

                    if (value && !value.includes("course:view")) {
                      form.setFieldsValue({
                        import: form
                          .getFieldValue("import")
                          ?.filter(
                            (value: any) =>
                              value !== "course" &&
                              value !== "questions" &&
                              value !== "registry",
                          ),
                        export: form
                          .getFieldValue("export")
                          ?.filter(
                            (value: any) =>
                              value !== "export_command" &&
                              value !== "export_protocol" &&
                              value !== "export_for_registry",
                          ),
                        pool: [],
                        automation: [],
                        learning_matrix_entry: [],
                        pool_participant: [],
                        test_participant: [],
                        report: [],
                        dashboard: [],
                      });
                    }
                    if (value && value.includes("import:questions")) {
                      form.setFieldsValue({
                        test_participant: test_participant?.includes(
                          "test_participant:view",
                        )
                          ? test_participant
                          : ["test_participant:view"],
                      });
                    }
                  }}
                />
                <CheckboxFormFieldPermission
                  name="pool"
                  label="Потоки"
                  form={form}
                  options={[
                    { value: "pool:view", label: "Просмотр потока" },
                    { value: "pool:create", label: "Создание потока" },
                    { value: "pool:update", label: "Редактирование потока" },
                    { value: "pool:delete", label: "Удаление потока" },
                  ]}
                  onChange={() => {
                    const value = form.getFieldValue("pool");
                    const course = form.getFieldValue("course");

                    if (value && !value.includes("pool:view")) {
                      form.setFieldsValue({
                        import: form
                          .getFieldValue("import")
                          ?.filter((value: any) => value !== "registry"),
                        export: form
                          .getFieldValue("export")
                          ?.filter(
                            (value: any) =>
                              value !== "export_command" &&
                              value !== "export_protocol" &&
                              value !== "export_for_registry",
                          ),
                        pool_participant: [],
                        automation: [],
                        test_participant: [],
                        report: [],
                        dashboard: [],
                      });
                    }
                    if (value && value.length > 0) {
                      form.setFieldsValue({
                        course: course?.includes("course:view")
                          ? course
                          : ["course:view"],
                      });
                    }
                  }}
                />

                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "pool_participant:view",
                      label: "Просмотр участника потока",
                    },
                    {
                      value: "pool_participant:create",
                      label: "Добавление участника в поток",
                    },
                    {
                      value: "pool_participant:update",
                      label: "Изменение периода обучения",
                    },
                    {
                      value: "pool_participant:delete",
                      label: "Удаление участника из потока",
                    },
                    {
                      value: "import:registry",
                      label: "Импорт номеров из реестра",
                    },
                    {
                      value: "export:export_protocol",
                      label: "Выгрузить протокол",
                    },
                    {
                      value: "export:export_command",
                      label: "Выгрузить приказ",
                    },
                    {
                      value: "export:export_for_registry",
                      label: "Выгрузить для реестра",
                    },
                  ]}
                  name="pool_participant"
                  label="Участники потока"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("pool_participant");
                    const member = form.getFieldValue("member");
                    const pool = form.getFieldValue("pool");
                    const course = form.getFieldValue("course");
                    const role = form.getFieldValue("role");
                    const orgStructure = form.getFieldValue("org_structure");

                    if (value && !value.includes("pool_participant:view")) {
                      form.setFieldsValue({
                        report: [],
                        dashboard: [],
                        test_participant: [],
                        export: form
                          .getFieldValue("export")
                          ?.filter(
                            (value: any) =>
                              value !== "export_command" &&
                              value !== "export_protocol" &&
                              value !== "export_for_registry",
                          ),
                        import: form
                          .getFieldValue("import")
                          ?.filter((value: any) => value !== "registry"),
                      });
                    }
                    if (value && value.length > 0) {
                      form.setFieldsValue({
                        member: member?.includes("member:view")
                          ? member
                          : ["member:view"],
                        org_structure: orgStructure?.includes(
                          "org_structure:view",
                        )
                          ? orgStructure
                          : ["org_structure:view"],
                        pool: pool?.includes("pool:view")
                          ? pool
                          : ["pool:view"],
                        role: role?.includes("role:view")
                          ? role
                          : ["role:view"],
                        course: course?.includes("course:view")
                          ? course
                          : ["course:view"],
                      });
                    }
                  }}
                />
                <CheckboxFormFieldPermission
                  options={[
                    { value: "role:view", label: "Просмотр роли" },
                    { value: "role:create", label: "Создание роли" },
                    { value: "role:update", label: "Редактирование роли" },
                    { value: "role:delete", label: "Удаление роли" },
                  ]}
                  name="role"
                  label="Роли пользователей"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("role");
                    const member = form.getFieldValue("member");

                    if (value && !value.includes("role:view")) {
                      form.setFieldsValue({
                        member: [],
                        report: [],
                        pool_participant: [],
                        dashboard: [],
                        test_participant: [],
                        export: form
                          .getFieldValue("export")
                          ?.filter(
                            (value: any) => value === "export_learning_matrix",
                          ),
                        import: form
                          .getFieldValue("import")
                          ?.filter(
                            (value: any) =>
                              value !== "members" && value !== "registry",
                          ),
                      });
                    }

                    if (value && value.includes("role:delete")) {
                      form.setFieldsValue({
                        member: member?.includes("member:update")
                          ? member
                          : ["member:update"],
                      });
                    }
                  }}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical">
                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "member:view",
                      label: "Просмотр карточки пользователя",
                    },
                    { value: "member:create", label: "Создание пользователя" },
                    {
                      value: "member:update",
                      label: "Редактирование информации о пользователе",
                    },
                    { value: "member:reset_password", label: "Сброс пароля" },
                    { value: "import:members", label: "Импорт пользователей" },
                    {
                      value: "export:export_members",
                      label: "Экспорт пользователей",
                    },
                  ]}
                  name="member"
                  label="Пользователи"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("member");
                    const role = form.getFieldValue("role");
                    const orgStructure = form.getFieldValue("org_structure");

                    if (value && !value.includes("member:view")) {
                      form.setFieldsValue({
                        report: [],
                        pool_participant: [],
                        dashboard: [],
                        test_participant: [],
                        export: form
                          .getFieldValue("export")
                          ?.filter(
                            (value: any) => value === "export_learning_matrix",
                          ),
                        import: form
                          .getFieldValue("import")
                          ?.filter(
                            (value: any) =>
                              value !== "members" && value !== "registry",
                          ),
                      });
                    }

                    if (value && !value.includes("member:update")) {
                      form.setFieldsValue({
                        role: role?.filter(
                          (permission: any) => permission !== "role:delete",
                        ),
                      });
                    }

                    if (value && value.length > 0) {
                      form.setFieldsValue({
                        org_structure: orgStructure?.includes(
                          "org_structure:view",
                        )
                          ? orgStructure
                          : ["org_structure:view"],
                        role: role?.includes("role:view")
                          ? role
                          : ["role:view"],
                      });
                    }
                  }}
                />
                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "dashboard:using",
                      label: "Просмотр и изменение показателей Дашборда",
                    },
                  ]}
                  name="dashboard"
                  label="Дашборд"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("dashboard");
                    const member = form.getFieldValue("member");
                    const pool_participant =
                      form.getFieldValue("pool_participant");
                    const pool = form.getFieldValue("pool");
                    const course = form.getFieldValue("course");
                    const role = form.getFieldValue("role");
                    const orgStructure = form.getFieldValue("org_structure");

                    if (value && value.length > 0) {
                      form.setFieldsValue({
                        report: ["report:view"],
                        pool_participant: pool_participant?.includes(
                          "pool_participant:view",
                        )
                          ? pool_participant
                          : ["pool_participant:view"],
                        pool: pool?.includes("pool:view")
                          ? pool
                          : ["pool:view"],
                        role: role?.includes("role:view")
                          ? role
                          : ["role:view"],
                        course: course?.includes("course:view")
                          ? course
                          : ["course:view"],
                        member: member?.includes("member:view")
                          ? member
                          : ["member:view"],
                        org_structure: orgStructure?.includes(
                          "org_structure:view",
                        )
                          ? orgStructure
                          : ["org_structure:view"],
                      });
                    }
                  }}
                />
                <CheckboxFormFieldPermission
                  name="report"
                  options={[
                    {
                      value: "report:view",
                      label: "Доступ к просмотру/выгрузке отчетов",
                    },
                  ]}
                  label="Отчеты"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("report");
                    const member = form.getFieldValue("member");
                    const pool_participant =
                      form.getFieldValue("pool_participant");
                    const pool = form.getFieldValue("pool");
                    const course = form.getFieldValue("course");
                    const role = form.getFieldValue("role");
                    const orgStructure = form.getFieldValue("org_structure");

                    if (value && !value.includes("report:view")) {
                      form.setFieldsValue({
                        dashboard: [],
                      });
                    }
                    if (value && value.length > 0) {
                      form.setFieldsValue({
                        member: member?.includes("member:view")
                          ? member
                          : ["member:view"],
                        org_structure: orgStructure?.includes(
                          "org_structure:view",
                        )
                          ? orgStructure
                          : ["org_structure:view"],
                        role: role?.includes("role:view")
                          ? role
                          : ["role:view"],
                        pool_participant: pool_participant?.includes(
                          "pool_participant:view",
                        )
                          ? pool_participant
                          : ["pool_participant:view"],
                        pool: pool?.includes("pool:view")
                          ? pool
                          : ["pool:view"],
                        course: course?.includes("course:view")
                          ? course
                          : ["course:view"],
                      });
                    }
                  }}
                />
                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "license:view",
                      label: "Просмотр информации о лицензии",
                    },
                  ]}
                  name="license"
                  label="Лицензия"
                  form={form}
                />
                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "service_account:view",
                      label: "Просмотр существующих сервисных аккаунтов",
                    },
                    {
                      value: "service_account:create",
                      label: "Создание сервисного аккаунта",
                    },
                    {
                      value: "service_account:update",
                      label: "Редактирование сервисного аккаунта",
                    },
                    {
                      value: "service_account:delete",
                      label: "Удаление сервисного аккаунта",
                    },
                  ]}
                  name="service_account"
                  label="Интеграции"
                  form={form}
                />
              </Space>
            </Col>
            <Col span={8} style={{ flexWrap: "nowrap" }}>
              <Space direction="vertical">
                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "learning_matrix_entry:view",
                      label: "Просмотр матрицы обучения",
                    },
                    {
                      value: "learning_matrix_entry:create",
                      label: "Создание матрицы обучения",
                    },
                    {
                      value: "learning_matrix_entry:update",
                      label: "Редактирование матрицы обучения",
                    },
                    {
                      value: "learning_matrix_entry:delete",
                      label: "Удаление матрицы обучения",
                    },
                    {
                      value: "import:learning_matrix",
                      label: "Импорт записей матрицы",
                    },
                    {
                      value: "export:export_learning_matrix",
                      label: "Экспорт записей матрицы",
                    },
                  ]}
                  name="learning_matrix_entry"
                  label="Матрица обучения"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("learning_matrix_entry");
                    const course = form.getFieldValue("course");
                    const orgStructure = form.getFieldValue("org_structure");

                    if (
                      value &&
                      !value.includes("learning_matrix_entry:view")
                    ) {
                      form.setFieldsValue({
                        automation: [],
                        export: form
                          .getFieldValue("export")
                          ?.filter(
                            (value: any) => value !== "export_learning_matrix",
                          ),
                        import: form
                          .getFieldValue("import")
                          ?.filter((value: any) => value !== "learning_matrix"),
                      });
                    }
                    if (value && value.length > 0) {
                      form.setFieldsValue({
                        course: course?.includes("course:view")
                          ? course
                          : ["course:view"],
                        org_structure: orgStructure?.includes(
                          "org_structure:view",
                        )
                          ? orgStructure
                          : ["org_structure:view"],
                      });
                    }
                  }}
                />
                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "space:view",
                      label: "Просмотр информации о компании",
                    },
                    {
                      value: "space:update",
                      label: "Редактирование информации о компании",
                    },
                  ]}
                  name="space"
                  label="Компания"
                  form={form}
                />

                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "automation:view",
                      label: "Просмотр условия автоназначения",
                    },
                    {
                      value: "automation:create",
                      label: "Создание условия автоназначения",
                    },
                    {
                      value: "automation:update",
                      label: "Редактирование условия автоназначения",
                    },
                    {
                      value: "automation:delete",
                      label: "Удаление условия автоназначения",
                    },
                  ]}
                  name="automation"
                  label="Автоназначение"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("automation");
                    const pool = form.getFieldValue("pool");
                    const course = form.getFieldValue("course");
                    const learning_matrix_entry = form.getFieldValue(
                      "learning_matrix_entry",
                    );
                    const orgStructure = form.getFieldValue("org_structure");

                    if (value && value.length > 0) {
                      form.setFieldsValue({
                        org_structure: orgStructure?.includes(
                          "org_structure:view",
                        )
                          ? orgStructure
                          : ["org_structure:view"],
                        learning_matrix_entry: learning_matrix_entry?.includes(
                          "learning_matrix_entry:view",
                        )
                          ? learning_matrix_entry
                          : ["learning_matrix_entry:view"],
                        pool: pool?.includes("pool:view")
                          ? pool
                          : ["pool:view"],
                        course: course?.includes("course:view")
                          ? course
                          : ["course:view"],
                      });
                    }
                  }}
                />

                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "test_participant:view",
                      label: "Просмотр отчетов по тесту ",
                    },
                    {
                      value: "test_participant:create",
                      label: "Добавление попытки",
                    },
                    {
                      value: "test_participant:update",
                      label: "Изменение попытки",
                    },
                  ]}
                  name="test_participant"
                  label="Тесты"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("test_participant");
                    const pool_participant =
                      form.getFieldValue("pool_participant");
                    const member = form.getFieldValue("member");
                    const pool = form.getFieldValue("pool");
                    const course = form.getFieldValue("course");
                    const role = form.getFieldValue("role");
                    const orgStructure = form.getFieldValue("org_structure");

                    if (value && !value.includes("test_participant:view")) {
                      form.setFieldsValue({
                        course: course?.filter(
                          (permission: any) =>
                            permission !== "import:questions",
                        ),
                      });
                    }

                    if (value && value.length > 0) {
                      form.setFieldsValue({
                        pool_participant: pool_participant?.includes(
                          "pool_participant:view",
                        )
                          ? pool_participant
                          : ["pool_participant:view"],
                        pool: pool?.includes("pool:view")
                          ? pool
                          : ["pool:view"],
                        role: role?.includes("role:view")
                          ? role
                          : ["role:view"],
                        course: course?.includes("course:view")
                          ? course
                          : ["course:view"],
                        member: member?.includes("member:view")
                          ? member
                          : ["member:view"],
                        org_structure: orgStructure?.includes(
                          "org_structure:view",
                        )
                          ? orgStructure
                          : ["org_structure:view"],
                      });
                    }
                  }}
                />
                <CheckboxFormFieldPermission
                  options={[
                    {
                      value: "org_structure:view",
                      label: "Просмотр организационной структуры",
                    },
                    {
                      value: "org_structure:create",
                      label: "Создание организационной структуры",
                    },
                    {
                      value: "org_structure:update",
                      label: "Редактирование организационной структуры",
                    },
                    {
                      value: "org_structure:delete",
                      label: "Удаление организационной структуры",
                    },
                  ]}
                  name="org_structure"
                  label="Управления структурой организации"
                  form={form}
                  onChange={() => {
                    const value = form.getFieldValue("org_structure");

                    if (value && !value.includes("org_structure:view")) {
                      form.setFieldsValue({
                        member: [],
                        report: [],
                        pool_participant: [],
                        dashboard: [],
                        test_participant: [],
                        export: [],
                        import: form
                          .getFieldValue("import")
                          ?.filter(
                            (value: any) =>
                              value !== "members" &&
                              value !== "registry" &&
                              value !== "learning_matrix" &&
                              value !== "registry",
                          ),
                        automation: [],
                        learning_matrix_entry: [],
                      });
                    }
                  }}
                />
              </Space>
            </Col>
          </Row>
        </ProForm>
      </Spin>
    </>
  );
};
export default RolesForm;
export type { RolesFormProps };
