import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Flex,
  message,
  Modal,
  Row,
  Space,
  Tabs as AntdTabs,
  TabsProps,
  theme,
  Typography,
  UploadFile,
} from "antd";
import { Member, MemberPosition } from "@/models";
import useMe from "@/entities/me/lib/use";
import {
  ModalForm,
  ModalFormProps,
  ProDescriptions,
  ProForm,
  ProFormDatePicker,
  ProFormDigit,
  ProFormList,
  ProFormProps,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import { FieldData } from "rc-field-form/es/interface";
import {
  OrionRestBatchCreateResponse,
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useSpace from "@/entities/space/lib/use";
import { date, dateNormalize } from "@/shared/dayjs/lib/formats";
import ProFormPhone from "@/shared/ant-design-pro-components/form-phone/ui/component.tsx";
import ProFormSnils from "@/shared/ant-design-pro-components/form-snils/ui/component.tsx";
import useQueryTabs from "@/shared/hooks/use-query-tab";
import PoolParticipantTable from "@/entities/pool-participant/ui/table";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";
import { statusValueEnum } from "@/entities/member/lib/status-value-enum.tsx";
import emailPattern from "@/shared/patterns/lib/email-pattern.tsx";
import RolesSearchSelect from "@/entities/roles/select";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useFeatures from "@/entities/features/lib/use.ts";
import OrgStructureTreeSelect from "@/entities/org-structure/ui/tree-select";
import UploadWithPreview from "@/entities/file/ui/upload-with-preview";

const Tabs = styled(AntdTabs)`
  .ant-tabs-content {
    height: 100%;
  }
`;
type FormData = Omit<Member, "avatar_image_file"> & {
  avatar_image_file: (File & UploadFile)[] | null;
};
type Props = ProFormProps<FormData> & {
  modal?: boolean;
  mode?: "default" | "personal";
  rest: RestProps<Member>;
  memberId?: number;
};

const MemberForm: React.FC<Props> = ({
  modal = false,
  mode = "default",
  rest,
  ...props
}) => {
  const features = useFeatures();
  const { t } = useTranslation();
  const member = useMe();
  const space = useSpace();
  const [form] = ProForm.useForm<FormData>(props.form);
  const [toDeleteOrgStructureAssignments, setToDeleteOrgStructureAssignments] =
    React.useState<MemberPosition[]>([]);
  const memberPositions = ProForm.useWatch("positions", form);
  const [toRemoveFileIds, setToRemoveFileIds] = useState<number[]>([]);
  const [uploadListType, setUploadListType] = useState<
    "text" | "picture-card" | undefined
  >(rest.type === "create" ? "picture-card" : undefined);

  const [incorrectImage, setIncorrectImage] = useState(false);

  const [modalMember, modalMemberHolder] = Modal.useModal();
  const { token } = theme.useToken();

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const generateLogin = async () => {
    const generated = await axios
      .post<{ data: string }>(
        "/api/members/generate-username",
        form.getFieldsValue(),
      )
      .then(({ data }) => data.data)
      .catch((err) => {
        if (err.response.status === 422) {
          message.error(
            err.response.data.message ?? "Ошибка при генерации логина",
          );
          setValidationErrorsToFormFields(form, err.response.data.errors);
        }

        return "";
      });
    form.setFieldsValue({ username: generated });
  };

  const onRemove = (file: any) => {
    if (incorrectImage) {
      setIncorrectImage(false);
    }
    if (file.id) setToRemoveFileIds((prev) => [...prev, file.id]);
  };

  const showUserModalOnCreate = (member: Member) => {
    modalMember.info({
      title: `Информация о созданном пользователе`,
      okText: "Скопировать все и закрыть",
      onOk: () => {
        let copiedData = `ФИО: ${member.full_name}`;

        if (member.email) {
          copiedData += `\nПочта: ${member.email}`;
        }

        if (member.snils) {
          copiedData += `\nСНИЛС: ${member.snils}`;
        }

        if (member.username) {
          copiedData += `\nЛогин: ${member.username}`;
        }
        copy(copiedData);
        message.info("Данные скопированы в буфер обмена");
      },
      width: "60%",
      icon: null,
      content: (
        <Space direction="vertical">
          <ProDescriptions
            column={1}
            dataSource={member}
            layout="horizontal"
            columns={[
              {
                dataIndex: "full_name",
                title: <Typography.Text>ФИО</Typography.Text>,
              },
              {
                dataIndex: "snils",
                hideInDescriptions: !member.snils,
                title: <Typography.Text>СНИЛС</Typography.Text>,
              },
              {
                dataIndex: "email",
                hideInDescriptions: !member.email,
                title: <Typography.Text>Почта</Typography.Text>,
              },
              {
                dataIndex: "username",
                hideInDescriptions: !member.username,
                title: <Typography.Text>Логин</Typography.Text>,
              },
            ]}
          />
        </Space>
      ),
    });
  };

  const checkDublicateOrgStructureAssignments = (
    orgStructureEntries: MemberPosition[],
  ) => {
    const indexesOrgStructureEntries = {
      doubleIndex: [],
    } as any;

    orgStructureEntries.forEach((item, index) => {
      if (!item.position_id) return;
      if (`${item.position_id}` in indexesOrgStructureEntries) {
        indexesOrgStructureEntries.doubleIndex.push(index);
      }
      indexesOrgStructureEntries[`${item.position_id}`] = index;
    });

    if (indexesOrgStructureEntries.doubleIndex.length > 0) {
      form.setFields(
        indexesOrgStructureEntries.doubleIndex.map(
          (index: number): FieldData => ({
            name: ["positions", index, "position_id"],
            errors: [
              t(
                "Должность дублируется, выберите другое Должность или удалите поле",
              ),
            ],
          }),
        ),
      );

      return true;
    }
    return false;
  };

  const syncAvatarImageFile = async (values: FormData) => {
    const { avatar_image_file } = values;
    if (avatar_image_file) {
      for (const toRemoveFileId of toRemoveFileIds) {
        await axios.delete(`/api/files/${toRemoveFileId}`);
        setToRemoveFileIds([]);
      }
      for (let i = 0; i < avatar_image_file.length; i++) {
        const item: any = avatar_image_file[i];
        if (!item.originFileObj || (item.status && item.status !== "done"))
          continue;
        const key = item.response.key;
        await axios
          .post(`/api/files`, {
            key,
            name: item.name,
            type: item.type,
            attached_to_id: values.id,
            attached_to_type: "member",
          })
          .then(({ data }) => (avatar_image_file[i] = data.data))
          .then((file) => {
            return axios.put<OrionRestUpdateResponse<Member>>(
              `/api/members/${values.id}`,
              { ...values, avatar_image_file_id: file.id },
            );
          });
      }
    }
  };

  let defaultProps: Partial<typeof props> = {
    submitter: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  // -------------------------------- REST Type Create -------------------------------- //

  if (rest.type === "create") {
    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<Member>>("/api/members", values)
        .then(async (res) => {
          message.success("Пользователь успешно создан");
          rest.onAfterCreate?.(res.data.data);
          await syncAvatarImageFile({ ...values, id: res.data.data.id });
          showUserModalOnCreate(res.data.data);
          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ?? "Ошибка при создании пользователя",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  // -------------------------------- REST Type Update -------------------------------- //

  if (rest.type === "update") {
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<Member>>(`/api/members/${rest.recordKey}`, {
          params: {
            includes: [
              { relation: "positions" },
              { relation: "avatar_image_file" },
            ],
          },
        })
        .then((res) => {
          if (res.data.data.avatar_image_file) {
            setUploadListType("text");
          } else {
            setUploadListType("picture-card");
          }
          return {
            ...res.data.data,
            avatar_image_file: res.data.data.avatar_image_file
              ? [{ ...res.data.data.avatar_image_file, status: "done" }]
              : null,
          } as FormData;
        });
    };
    defaultProps.onFinish = async (values) => {
      if (
        values.positions &&
        checkDublicateOrgStructureAssignments(values.positions)
      ) {
        return false;
      }

      setIsSubmitButtonLoading(true);

      return axios
        .put<OrionRestUpdateResponse<Member>>(
          `/api/members/${rest.recordKey}`,
          { ...values },
        )
        .catch((err) => {
          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
            setActiveTab(
              Object.keys(err.response.data.errors)[0].startsWith("education")
                ? "education"
                : "personal",
            );
          }

          throw err;
        })
        .then(async (res) => {
          if (toDeleteOrgStructureAssignments?.length === 0) return res;
          await axios
            .delete(`/api/member-positions/batch`, {
              data: {
                resources: toDeleteOrgStructureAssignments.map((el) => el.id),
              },
            })
            .then(() => {
              setToDeleteOrgStructureAssignments([]);
            });
          return res;
        })
        .then(async (res) => {
          if (!memberPositions) return res;
          const toUpdateOrgStructureAssignments = memberPositions.filter(
            (el) => el.id,
          );
          if (
            toUpdateOrgStructureAssignments.length === 0 ||
            !member.permissions?.includes("member:update")
          )
            return res;

          await axios.patch(`/api/member-positions/batch`, {
            resources: toUpdateOrgStructureAssignments
              .map((el) => {
                return {
                  id: el.id ?? null,
                  member_id: el.member_id ?? null,
                  position_id: el.position_id ?? null,
                };
              })
              .reduce((acc, assigment) => {
                acc[assigment.id] = assigment;
                return acc;
              }, {} as Record<string, Partial<MemberPosition>>),
          });
          return res;
        })
        .then(async (res) => {
          if (!memberPositions) return res;
          const toCreateOrgStructureAssignments = memberPositions.filter(
            (el) => !el.id,
          );
          if (toCreateOrgStructureAssignments.length === 0) return res;
          await axios.post<OrionRestBatchCreateResponse<MemberPosition>>(
            `/api/member-positions/batch`,
            {
              resources: toCreateOrgStructureAssignments.map((el) => {
                return {
                  id: el.id ?? null,
                  member_id: el.member_id ?? null,
                  position_id: el.position_id ?? null,
                };
              }),
            },
          );
          return res;
        })
        .then(async (res) => {
          await syncAvatarImageFile({ ...values, id: res.data.data.id });
          return res;
        })
        .then(async (res) => {
          // @ts-ignore
          form.setFieldsValue(await defaultProps.request());
          message.success("Пользователь успешно обновлен");
          rest.onAfterUpdate?.(res.data.data);
          if (res.data.data.id === member.id) {
            member.refresh();
          }
          return true;
        })
        .catch((err) => {
          const messageText = err.response.data.message ?? err.message;
          message.error(`Ошибка при обновлении пользователя: ${messageText}`);

          return false;
        })
        .finally(() => setIsSubmitButtonLoading(false));
    };
  }

  // -------------------------------- Pre Render -------------------------------- //

  const FormComponent = modal ? ModalForm<FormData> : ProForm<FormData>;
  props = deepmerge(defaultProps, props, overrideProps);

  // -------------------------------- Type Modal -------------------------------- //

  if (modal) {
    const triggerText = rest.type === "create" ? "Создать" : "Редактировать";
    const disabled = rest.type === "update" && rest.recordKey === undefined;

    const modalFormProps: Partial<ModalFormProps<Member>> = {
      title:
        rest.type === "create"
          ? "Создание пользователя"
          : "Редактирование пользователя",
      trigger: (
        <Button type={"primary"} disabled={disabled}>
          {triggerText}
        </Button>
      ),
      modalProps: {
        destroyOnClose: true,
      },
    };

    props = deepmerge(modalFormProps, props);
  }

  // -------------------------------- Render -------------------------------- //

  const employeeEditableSelf =
    mode === "personal"
      ? !(
          space.is_allow_employee_edit_self_member ||
          member.permissions.includes("member:update")
        )
      : rest.type === "update"
      ? !member.permissions.includes("member:update")
      : !member.permissions.includes("member:create");

  const employeeEditableOrgStructure =
    rest.type === "update"
      ? !member.permissions.includes("member:update")
      : !member.permissions.includes("member:create");

  const Submitter: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
    return (
      <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
        <Button
          disabled={disabled}
          type={"primary"}
          loading={isSubmitButtonLoading}
          onClick={async () => {
            try {
              await form.validateFields();
            } catch (error: any) {
              const errorFieldName = error.errorFields[0].name[0];
              if (errorFieldName.startsWith("education")) {
                setActiveTab("education");
              } else if (errorFieldName.startsWith("positions")) {
                setActiveTab("positions");
              } else if (activeTab !== "personal") {
                setActiveTab("personal");
              }
              return;
            }
            form.submit();
          }}
        >
          {rest.type === "create" ? "Создать" : "Сохранить"}
        </Button>
      </Flex>
    );
  };

  const PersonalFormFields: React.FC = () => {
    return (
      <Row
        gutter={token.paddingXS}
        style={{ width: "100%", height: "calc(100% - 32px)" }}
      >
        <Col
          xs={24}
          sm={24}
          md={rest.type === "update" ? 12 : 24}
          style={{ height: "100%" }}
        >
          <ProFormText
            label={"Фамилия"}
            name={"last_name"}
            rules={[
              { max: 255, required: true },
              {
                pattern: /^[\p{L}-]*$/u,
                message: "Некорректное значение поля Фамилия",
              },
            ]}
            transform={(value) => value.trim()}
          />

          <ProFormText
            label={"Имя"}
            name={"first_name"}
            transform={(value) => value.trim()}
            rules={[
              { max: 255, required: true },
              {
                pattern: /^[\p{L}-]*$/u,
                message: "Некорректное значение поля Имя",
              },
            ]}
          />

          <ProFormText
            transform={(value) => value.trim()}
            label={"Отчество"}
            name={"middle_name"}
            rules={[
              { max: 255, required: false },
              {
                pattern: /^[\p{L}-]*$/u,
                message: "Некорректное значение поля Отчество",
              },
            ]}
          />
          <ProFormDatePicker
            label="Дата рождения"
            name="birth_date"
            rules={[
              { required: features.isEnabled("apk_usernames_and_passwords") },
            ]}
            hidden={
              !features.isEnabled("apk_usernames_and_passwords") &&
              rest.type !== "update"
            }
            fieldProps={{ format: date, style: { width: "100%" } }}
            normalize={dateNormalize}
          />
          <ProFormRadio.Group
            label="Пол"
            name="gender"
            rules={[{ required: false }]}
            hidden={rest.type !== "update"}
            options={[
              { value: "male", label: "Мужской" },
              { value: "female", label: "Женский" },
            ]}
            fieldProps={{ optionType: "button", buttonStyle: "solid" }}
          />

          <ProFormText
            label="Гражданство"
            name="citizenship"
            rules={[{ required: false, max: 255 }]}
            hidden={rest.type !== "update"}
          />
        </Col>

        <Col
          xs={24}
          sm={24}
          md={rest.type === "update" ? 12 : 24}
          style={{ height: "100%" }}
        >
          <ProFormSnils
            disabled={employeeEditableSelf}
            label={"СНИЛС"}
            name={"snils"}
            style={{ padding: `0 8px` }}
            rules={[{ required: false }]}
          />
          <ProFormPhone
            disabled={employeeEditableSelf}
            label={"Телефон"}
            name={"phone"}
            style={{ padding: `0 8px` }}
            rules={[{ required: false }]}
            hidden={rest.type !== "update"}
          />
          <ProFormText
            label={"Почта"}
            name={"email"}
            rules={[
              {
                required: space.authentication_types.includes("email+password"),
              },
              {
                type: "email",
                message: "Атрибут email должен быть действительным email",
              },
              { pattern: emailPattern, message: "Некорректный формат почты" },
            ]}
          />
          <Flex vertical style={{ position: "relative", width: "100%" }}>
            <Button
              type="link"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 1,
                padding: 0,
                paddingBottom: 4,
              }}
              onClick={generateLogin}
            >
              Сгенерировать
            </Button>
            <ProFormText
              label={"Логин"}
              name={"username"}
              rules={[
                {
                  required:
                    space.authentication_types.includes("username+password"),
                  max: 255,
                },
              ]}
            />
          </Flex>

          <RolesSearchSelect
            label="Роль"
            name="role_id"
            hidden={
              mode === "personal"
                ? !member.permissions.includes("member:update")
                : false
            }
            rules={[{ required: true }]}
          />

          {(member.permissions.includes("member:update") ||
            member.permissions.includes("member:create")) &&
            mode === "default" && (
              <ProFormSelect
                label="Статус"
                name="status"
                rules={[{ required: rest.type === "update" }]}
                hidden={rest.type !== "update"}
                valueEnum={statusValueEnum}
              />
            )}
          <UploadWithPreview
            type="button"
            name="avatar_image_file"
            label="Фотография профиля"
            max={1}
            fieldProps={{
              listType: uploadListType,
              onRemove,
              accept: ".png, .jpg, .jpeg",
              onChange: (info) => {
                if (
                  info.fileList.length > 0 &&
                  info.fileList[0].status === "done"
                ) {
                  setUploadListType("text");
                } else {
                  setUploadListType("picture-card");
                }
              },
            }}
            title="Загрузить"
            rules={[
              { required: false },
              () => ({
                validator(_, files) {
                  if (files) {
                    for (const file of files) {
                      if (incorrectImage) {
                        return Promise.reject(
                          new Error(
                            "Файл поврежден или не является изображением",
                          ),
                        );
                      }
                      if (!file.type) {
                        return Promise.reject(
                          new Error("Не удалось определить тип файла"),
                        );
                      }

                      if (file.originFileObj) {
                        const img = new Image();
                        img.src = URL.createObjectURL(file.originFileObj!);
                        img.onerror = () => {
                          if (!incorrectImage) {
                            setIncorrectImage(true);
                          }
                        };
                      }

                      const isImage = [
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                      ].includes(file.type);

                      if (!isImage) {
                        return Promise.reject(
                          new Error(
                            "Недопустимый тип файла: Можно загружать файлы с расширением PNG, JPG или JPEG",
                          ),
                        );
                      }
                    }
                  }
                  if (incorrectImage) {
                    setIncorrectImage(false);
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          />
        </Col>
      </Row>
    );
  };

  const tabs: TabsProps["items"] = [
    ...(mode !== "personal" &&
    rest.type === "update" &&
    member.permissions.includes("pool_participant:view")
      ? [
          {
            key: "pools",
            style: { height: "100%" },
            label: "Назначенное обучение",
            children: (
              <PoolParticipantTable
                hasQueryParams
                memberId={Number(rest.recordKey)}
                options={{
                  setting: {
                    draggable: false,
                    showListItemOption: false,
                  },
                  reload: false,
                  density: false,
                }}
                columnsState={{
                  persistenceKey: `pool-participant-member-table`,
                }}
                toolBarActionsProps={{
                  create: { triggerProps: { style: { display: "none" } } },
                  remove: { children: "Удалить обучение" },
                }}
                columns={[
                  { dataIndex: "registry_number" },
                  {
                    dataIndex: ["pool", "course", "id"],
                    render: (dom, { pool: { course } }) =>
                      course.deleted_at === null ? (
                        <Link to={`/manage/courses/${course.id}`}>{dom}</Link>
                      ) : (
                        <Typography.Text>{dom}</Typography.Text>
                      ),
                    hideInSetting: true,
                    disable: true,
                  },
                  {
                    dataIndex: ["pool", "id"],
                    render: (dom, { pool: { course, ...pool } }) =>
                      course.deleted_at === null ? (
                        <Link
                          to={`/manage/courses/${course.id}/pools/${pool.id}`}
                        >
                          {dom}
                        </Link>
                      ) : (
                        <Typography.Text>{dom}</Typography.Text>
                      ),
                    hideInSetting: true,
                    disable: true,
                  },
                  { dataIndex: "status" },
                  { dataIndex: "progress_percent" },
                  { dataIndex: "starts_at" },
                  { dataIndex: "started_at" },
                  { dataIndex: "ends_at" },
                  { dataIndex: "ended_at" },
                ]}
              />
            ),
          },
        ]
      : []),

    {
      key: "personal",
      label: "Личная информация",
      style: { height: "100%" },
      forceRender: true,
      children: (
        <Space
          direction="vertical"
          style={{
            padding: token.paddingMD,
            height: "100%",
            overflowY: "auto",
            paddingTop: 0,
            width: "100%",
          }}
        >
          <ProForm
            style={{ height: "100%" }}
            disabled={employeeEditableSelf}
            form={form}
            grid={true}
            {...props}
          >
            <Submitter disabled={employeeEditableSelf} />
            <PersonalFormFields />
          </ProForm>
        </Space>
      ),
    },
    {
      key: "positions",
      label: "Работа",
      style: { height: "100%" },
      forceRender: true,
      children: (
        <Space
          direction={"vertical"}
          style={{
            width:
              mode === "personal"
                ? "calc(100vw - 32px)"
                : "calc(100vw - 200px - 32px)",
            maxWidth:
              mode === "personal"
                ? "calc(1920px - 32px)"
                : "calc(1920px - 200px - 32px)",
            overflowY: "auto",
            height: "100%",
            padding: token.paddingMD,
            paddingTop: 0,
          }}
        >
          <ProForm
            disabled={employeeEditableOrgStructure}
            form={form}
            style={{ width: "100%" }}
            grid={true}
            {...props}
          >
            <Submitter disabled={employeeEditableOrgStructure} />
            <Row gutter={token.paddingXS} style={{ width: "100%" }}>
              <Col span={24}>
                <ProFormList
                  max={5}
                  name={"positions"}
                  creatorRecord={{
                    // @ts-ignore
                    member_id: rest.recordKey ?? undefined,
                  }}
                  creatorButtonProps={{
                    creatorButtonText: "Добавить запись о работе",
                    type: "primary",
                    block: false,
                    icon: false,
                    style: {
                      marginLeft: "4px",
                      display: !employeeEditableOrgStructure ? "block" : "none",
                    },
                  }}
                  copyIconProps={false}
                  deleteIconProps={
                    employeeEditableOrgStructure
                      ? false
                      : { tooltipText: "Удалить" }
                  }
                  onAfterRemove={async (index) => {
                    if (
                      memberPositions &&
                      typeof index === "number" &&
                      memberPositions[index].id
                    ) {
                      setToDeleteOrgStructureAssignments((prev) => [
                        ...prev,
                        memberPositions[index],
                      ]);
                    }
                  }}
                >
                  {(_, index) => {
                    return (
                      <ProForm.Group>
                        <ProFormDigit name={"id"} hidden />
                        <ProFormDigit name={"member_id"} hidden />

                        <OrgStructureTreeSelect
                          labelWithPath
                          label={t("Должность")}
                          name={"position_id"}
                          rules={[{ required: true }]}
                        />
                      </ProForm.Group>
                    );
                  }}
                </ProFormList>
              </Col>
            </Row>
          </ProForm>
        </Space>
      ),
    },
    {
      key: "education",
      label: "Образование",
      style: { height: "100%" },
      forceRender: true,
      children: (
        <Space
          direction="vertical"
          style={{
            padding: token.paddingMD,
            overflowY: "auto",
            paddingTop: 0,
            width: "100%",
          }}
        >
          <ProForm
            disabled={employeeEditableSelf}
            form={form}
            grid={true}
            {...props}
          >
            <Submitter disabled={employeeEditableSelf} />
            <Row gutter={token.paddingXS} style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={12}>
                <ProFormText
                  label="Уровень образования"
                  name="education_level"
                  rules={[{ required: false, max: 255 }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Серия диплома"
                  name="education_diploma_series"
                  rules={[{ required: false, max: 255, min: 10 }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Номер диплома"
                  name="education_diploma_number"
                  rules={[{ required: false }]}
                  hidden={rest.type !== "update"}
                />
                <ProFormText
                  label="Фамилия в дипломе"
                  name="education_diploma_last_name"
                  rules={[{ required: false, max: 255 }]}
                  hidden={rest.type !== "update"}
                />
              </Col>
            </Row>
          </ProForm>
        </Space>
      ),
    },

    ...(mode === "personal" && rest.type === "update"
      ? [
          {
            key: "settings",
            label: "Настройки",
            style: { height: "100%" },
            children: (
              <Space
                direction="vertical"
                style={{
                  padding: token.paddingMD,
                  height: "100%",
                  overflowY: "auto",
                  paddingTop: 0,
                  width: "100%",
                }}
              >
                <ProForm form={form} grid={true} {...props}>
                  <Submitter />
                  <Row gutter={token.paddingXS} style={{ width: "100%" }}>
                    <Col xs={24} sm={24} md={12}>
                      <ProFormSelect
                        label="Тема"
                        name="theme"
                        options={[
                          { label: "Светлая", value: "light" },
                          { label: "Темная", value: "dark" },
                          { label: "Авто", value: "auto" },
                        ]}
                      />
                    </Col>
                  </Row>
                </ProForm>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const [activeTab, setActiveTab] = useQueryTabs(
    rest.type === "create"
      ? undefined
      : mode === "personal" ||
        !member.permissions.includes("pool_participant:view")
      ? "personal"
      : "pools",
    tabs,
  );

  return (
    <>
      {rest.type === "create" && (
        <>
          {modalMemberHolder}
          <FormComponent
            form={form}
            grid={true}
            {...props}
            submitter={{
              resetButtonProps: false,
              searchConfig: {
                submitText: "Создать",
              },
            }}
          >
            <PersonalFormFields />
          </FormComponent>
        </>
      )}
      {rest.type === "update" && (
        <Tabs
          activeKey={activeTab}
          items={tabs}
          destroyInactiveTabPane={false}
          onChange={setActiveTab}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </>
  );
};
export default MemberForm;
