import React, { useEffect } from "react";
import useSWR from "swr";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { Notification } from "@/models";
import { AxiosRequestConfig } from "axios";
import { message, Spin } from "antd";
import useMe from "@/entities/me/lib/use";
import { BellFilled } from "@ant-design/icons";

interface Props {
  children: React.ReactNode;
}

const Notifications: React.FC<Props> = ({ children }) => {
  const member = useMe();
  const { data, error, isLoading } = useSWR(
    ["/api/notifications/search", "un-pushed"],
    ([url]) => {
      const actionConfig: AxiosRequestConfig = {
        method: "POST",
        url,
        data: {
          filters: [
            {
              field: "is_pushed",
              operator: "=",
              value: false,
            },
            {
              field: "is_read",
              operator: "=",
              value: false,
            },
            {
              field: "member_id",
              operator: "=",
              value: member.id,
            },
          ],
          sort: [
            {
              field: "created_at",
              order: "asc",
            },
          ],
        },
      };

      return axios
        .request<OrionRestIndexResponse<Notification>>(actionConfig)
        .then((res) => res.data.data);
    },
  );

  const onPushed = async (record: Notification) => {
    await axios
      .put(`/api/notifications/${record.id}`, { ...record, is_pushed: true })
      .catch((error) => {
        message.error(error.response.data.message ?? "Что-то пошло не так");
        throw error;
      });
  };

  useEffect(() => {
    if (data && data.length > 0) {
      data.forEach((notification) => {
        message.info({
          content: notification.text,
          icon: <BellFilled />,
        });
        onPushed(notification);
      });
    }
  }, [data]);

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!data) throw new Error("Data is undefined");

  return <>{children}</>;
};

export default Notifications;
